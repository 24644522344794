/* eslint-disable no-param-reassign */
import router from "@/router";
import endpoints from "@/api/auth";
import promisify from "@/services/promisify";
import jwt from "jwt-decode";
import moment from "moment";

const options = {
  endpoint: "api/auth",
  data: {},
};

const defaultState = {
  profile: null,
  accessToken: null,
  verification: null,
  isAuthenticated: false,
  isOnboardedUser: false,
  isInitializing: false,
  count: 0,
  requestedRoute: null,
  expires: null,
  loggedInUser: {},
  refreshedAt: null,
  redirectUri: null,
  pathRedirectUri: null,
  authorizationCode: null,
  authorizationState: null,
  user: null,
};
let localState = window.localStorage.getItem("protego-auth");
if (!localState) {
  window.localStorage.setItem("protego-auth", JSON.stringify(defaultState));
  localState = window.localStorage.getItem("protego-auth");
}

export default {
  state: JSON.parse(window.localStorage.getItem("protego-auth")),

  getters: {
    profile: (state) => state.profile,
    verification: (state) => state.verification,
    accessToken: (state) => state.accessToken,
    idToken: (state) => state.idToken,
    isAuthenticated: (state) => state.isAuthenticated,
    isOnboardedUser: (state) => state.isOnboardedUser,
    expires: (state) => state.expires,
    requestedRoute: (state) => state.requestedRoute,
    loggedInUser: (state) => state.loggedInUser,
    isInitializing: (state) => state.isInitializing,
    dataMappings: (state) => {
      if (!state.user) {
        return {};
      }
      return state.user.data_mappings;
    },
    permissions: (state) => {
      if (!state.user) {
        return [];
      }
      return state.user.applications.find((x) => x.name === "Financial Systems")
        .permissions;
    },
    canAccessList: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissions.map((x) => x.name).length > 0;
    },
    canAccessClientProductList: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissions
        .map((x) => x.name)
        .some((y) =>
          [
            "read-all_request-list",
            "read-own_request-list",
            "finance-approval_client-request",
            "ar-approval_client-request",
            "ic-approval_client-request",
            "approve-level-4_client-request",
            "create_client-request",
            "create_product-request",
            "finance-approval_product-request",
            "approve-level-2_product-request",
            "delete_client-request",
            "delete_product-request",
          ].includes(y)
        );
      },
      canAccessUserRolesList: (state, getters) => {
          if (!getters.permissions) {
              return false;
          }
          return getters.permissions
              .map((x) => x.name)
              .some((y) =>
                  [
                      // "read-all_request-list",
                      // "read-own_request-list",
                      // "finance-approval_client-request",
                      // "ar-approval_client-request",
                      // "ic-approval_client-request",
                      // "approve-level-4_client-request",
                      // "create_client-request",
                      // "create_product-request",
                      // "finance-approval_product-request",
                      // "approve-level-2_product-request",
                      // "delete_client-request",
                      // "delete_product-request",
                      "ar-approval_client-request",
                  ].includes(y)
              );
      },
      canAccessOtherForms: (state, getters) => {
            if (!getters.permissions) {
                return false;
            }
            return getters.permissions
                .map((x) => x.name)
                .some((y) => [
                    // "read-all_request-list",
                    // "read-own_request-list",
                    // "finance-approval_client-request",
                    // "ar-approval_client-request",
                    // "ic-approval_client-request",
                    // "approve-level-4_client-request",
                    // "create_client-request",
                    // "create_product-request",
                    // "finance-approval_product-request",
                    // "approve-level-2_product-request",
                    // "delete_client-request",
                    // "delete_product-request",
                    "ar-approval_client-request",
                ].includes(y));
        },
    canAccessICList: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissions
        .map((x) => x.name)
        .some((y) =>
          [
            "read-all_request-list",
            "read-own_request-list",
            "create_ic-request",
            "ic-approval_ic-request",
            "finance-approval_ic-request",
            "ax-approval_ic-request",
          ].includes(y)
        );
    },

    canAccessMediaTab: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissions
        .map((x) => x.name)
        .some((y) =>
          [
            "create_wire-transfer-request",
            "am-approval_wire-transfer-request",
            "cfo-approval_wire-transfer-request",
            "watcher_wire-transfer-request",
            "budget_project_management",
          ].includes(y)
        );
    },
    IsBPM: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("budget_project_management") > -1
      );
    },
    canAccessVendorList: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissions
        .map((x) => x.name)
        .some((x) =>
          [
            "read-all_request-list",
            "read-all_vendor-request",
            "read-own_request-list",
            "mr-manager_vendor-request",
            "mda-manager_vendor-request",
            "financial-systems_vendor-request",
            "finance-director_vendor-request",
            "vendor-validation_vendor-request",
            "accounting-approval_vendor-request",
            "cfo-approval_vendor-request",
            "financial-systems-completes_vendor-request",
            "reassigning-director-approval_vendor-request",
            "reassigned-director_vendor-request"
          ].includes(x)
        );
    },

    canCreateClient: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("create_client-request") > -1
      );
    },
    // Can create Product IC Vendor Request
    canCreateProductRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("create_product-request") > -1
      );
    },

    canCreateICRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions.map((x) => x.name).indexOf("create_ic-request") > -1
      );
    },

    canApprovePendingMrManagerVendorRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("mr-manager_vendor-request") > -1
      );
    },
    canApprovePendingFinanceDirectorVendorRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("finance-director_vendor-request") > -1
      );
    },
    canApprovePendingVendorValidation: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("vendor-validation_vendor-request") > -1
      );
    },
    canApprovePendingMdaManagerVendorRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("mda-manager_vendor-request") > -1
      );
    },
    canApprovePendingAccountingApprovalVendorRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("accounting-approval_vendor-request") > -1
      );
    },
    canApprovePendingCFOApprovalVendorRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("cfo-approval_vendor-request") > -1
      );
    },
    canApproveLevel2VendorRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("mda-manager_vendor-request") > -1
      );
    },
    canCompleteVendorRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("financial-systems-completes_vendor-request") > -1
      );
    },
    canReassignDirectorApprover: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("reassigning-director-approval_vendor-request") > -1
      );
    },
    canApproveReassignedDirectorApproval: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("reassigned-director_vendor-request") > -1
      );
    },
    canAccessProfileRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return getters.permissions
        .map((x) => x.name)
        .some((x) =>
          [
            "read-all_profile-unlock-request",
            "approve-level-1_profile-unlock-request",
            "mr-approval_profile-unlock-request",
            "unlockrelock-request_profile-unlock-request",
          ].includes(x)
        );
    },
    canReadProfileRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("read_profile-unlock-request") > -1
      );
    },
    canDeleteProfileRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("delete_profile-unlock-request") > -1
      );
    },

    canApproveProfileRequestLevel2: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("mr-approval_profile-unlock-request") > -1
      );
    },

    canApproveProfileRequestLevel1: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("approve-level-1_profile-unlock-request") > -1
      );
    },

    canApproveProfileRequestLevel3: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("unlockrelock-request_profile-unlock-request") > -1
      );
    },

    canApproveProfileRequestLevel4: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("unlockrelock-request_profile-unlock-request") > -1
      );
    },

    canApproveClientRequestLevel1: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("finance-approval_client-request") > -1
      );
    },
    canApproveClientRequestLevel2: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("ar-approval_client-request") > -1
      );
    },
    canApproveClientRequestLevel3IC: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("ic-approval_client-request") > -1
      );
    },
    canCompleteClientRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("approve-level-4_client-request") > -1
      );
    },
    canUpdateClientRequestCode: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("approve-level-4_client-request") > -1
      );
    },
    canApproveProductRequestLevel1: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("finance-approval_product-request") > -1
      );
    },
    canApproveProductRequestLevel2: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("approve-level-2_product-request") > -1
      );
    },
    canApproveICRequestLevel1: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("ic-approval_ic-request") > -1
      );
    },
    canApproveICRequestLevel2: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("finance-approval_ic-request") > -1
      );
    },
    canApproveICRequestLevel3: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("ax-approval_ic-request") > -1
      );
    },
    canDeleteClientRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("delete_client-request") > -1
      );
    },
    canDeleteProductRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("delete_product-request") > -1
      );
    },
    canDeleteICRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions.map((x) => x.name).indexOf("delete_ic-request") > -1
      );
    },
    canCreateWireTransferRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("create_wire-transfer-request") > -1
      );
    },
    canDeleteWireTransferRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("delete_wire-transfer-request") > -1
      );
    },
    canApproveLevel1WireTransferRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("am-approval_wire-transfer-request") > -1
      );
    },
    canApproveLevelCFOWireTransferRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("cfo-approval_wire-transfer-request") > -1
      );
    },
    canWatchWireTransferRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("watcher_wire-transfer-request") > -1
      );
    },
    canSubmitPayrollWireTransferRequest: (state, getters) => {
      if (!getters.permissions) {
        return false;
      }
      return (
        getters.permissions
          .map((x) => x.name)
          .indexOf("payroll-submitter_wire-transfer-request") > -1
      );
    },

    emailAddress: (state) => {
      if (state.profile && state.profile.identities) {
        return state.profile.identities[0].userId;
      }
      return null;
    },
    firstName: (state) => {
      if (state.profile) {
        return state.profile.given_name;
      }
      return null;
    },
    lastName: (state) => {
      if (state.profile) {
        return state.profile.family_name;
      }
      return null;
    },
  },

  mutations: {
    setauthorizationCode: (state, authorizationCode) => {
      state.authorizationCode = authorizationCode;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setRefreshToken: (state, refreshToken) => {
      state.refreshToken = refreshToken;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setauthorizationState: (state, authorizationState) => {
      state.authorizationState = authorizationState;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setredirectUri: (state, redirectUri) => {
      state.redirectUri = redirectUri;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setPathRedirectUri: (state, redirectUri) => {
      state.pathRedirectUri = redirectUri;
    },
    setProfile: (state, profile) => {
      // eslint-disable-next-line no-param-reassign
      state.profile = profile;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setAccessToken: (state, accessToken) => {
      // eslint-disable-next-line no-param-reassign
      state.accessToken = accessToken;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setIdToken: (state, idToken) => {
      // eslint-disable-next-line no-param-reassign
      state.idToken = idToken;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setVerification: (state, verification) => {
      // eslint-disable-next-line no-param-reassign
      state.verification = verification;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setAuthenticated: (state, isAuthenticated) => {
      // eslint-disable-next-line no-param-reassign
      state.isAuthenticated = isAuthenticated;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setRequestedRoute: (state, route) => {
      // eslint-disable-next-line no-param-reassign
      state.requestedRoute = route;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setExpires: (state, expires) => {
      // eslint-disable-next-line no-param-reassign
      state.expires = expires;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setUserData: (state, user) => {
      // eslint-disable-next-line no-param-reassign
      state.loggedInUser = user;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setOnboarded: (state, isOnboarded) => {
      // eslint-disable-next-line no-param-reassign
      state.isOnboardedUser = isOnboarded;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setInitializing: (state, init) => {
      // eslint-disable-next-line no-param-reassign
      state.isInitializing = init;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setPermissions: (state, perms) => {
      // eslint-disable-next-line no-param-reassign
      state.permissions = perms;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
    setRefreshed: (state, ref) => {
      // eslint-disable-next-line no-param-reassign
      state.refreshedAt = ref;
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },

    setUser: (state, user) => {
      window.pendo.initialize({
        visitor: {
          id: user?.email, // Required if user is logged in
          //,email:user.email        // Optional
          // role:         // Optional

          // You can add any additional visitor level key-values here,
          // as long as it's not one of the above reserved names.
        },

        account: {
          id: "FINSYSREQ", // Highly recommended
          // name:         // Optional
          // planLevel:    // Optional
          // planPrice:    // Optional
          // creationDate: // Optional

          // You can add any additional account level key-values here,
          // as long as it's not one of the above reserved names.
        },
      });
      state.user = user;
      if (user === null) {
        state.refreshedAt = null;
      } else {
        state.refreshedAt = new Date();
      }
      window.localStorage.setItem("protego-auth", JSON.stringify(state));
    },
  },

  actions: {
    setUserPermissions: (context) => {
      const pId = process.env.VUE_APP_SECURITY_APP_ID;
      const user = context.state.loggedInUser;
      if (user && user.applications) {
        const userIsInProtego =
          user && user.applications.filter((x) => x.id === pId).length > 0;
        if (userIsInProtego) {
          const protego = user.applications.find((x) => x.id === pId);
          context.commit("setPermissions", protego.permissions);
        }
      }
    },

    authenticate(context, payload) {
      return new Promise((resolve) => {
        context.commit("setauthorizationCode", payload.authorizationCode);
        context.commit("setauthorizationState", payload.state);
        context.dispatch("getToken").then((onboarded) => {
          resolve(onboarded);
        });
      });
    },

    getToken(context) {
      options.endpoint = "api/v1/auth/Gettoken";
      options.data.authorization_code = context.state.authorizationCode;
      options.data.redirect_uri = context.state.redirectUri;
      return endpoints
        .post(options)
        .then((response) => {
          context.dispatch("setToken", response.data);
          return response.data;
        })
        .catch((ex) => {
          if (ex.message == "Network Error") {
            router.push({
              name: "error",
              params: {
                message: "Network Error",
              },
            });
          }
        });
    },

    saveUserLoggedInActivity(context, data) {
      options.endpoint = "api/v1/user/SaveUserLoggedInActivity";
      options.data = data;
      options.token = context.state.idToken;
      return endpoints
        .post(options)
        .then((response) => {
          return response.data;
        })
        .catch((ex) => {
          if (ex.message == "Network Error") {
            router.push({
              name: "error",
              params: {
                message: "Network Error",
              },
            });
          }
        });
    },

    setProfile(context, profile) {
      context.state.profile = profile;
      return context.dispatch("getUser", false).then(
        () => context.getters.isOnboardedUser,
        () => new Error("Error retrieving user")
      );
    },

    getUser(context, login) {
      //const refreshedMoment = moment(context.state.refreshedAt);
      //if (!context.state.refreshedAt || refreshedMoment.add(2, 'minutes').isBefore(moment())) {
      return context.dispatch("getUserFromApi", login).then((user) => {
        context.commit("setUser", user);
        return user;
      });
      //}
      //return new Promise(resolve => resolve(context.state.user));
    },
    getEmail(context) {
      console.log("LOGIN USER EMAIL IS :", context.rootGetters.emailAddress);
      return context.rootGetters.emailAddress;
    },
    refreshToken(context) {
      options.endpoint = "api/v1/auth/refresh";
      options.data.refresh_token = context.state.refreshToken;
      options.data.redirect_uri = context.state.redirectUri;
      options.data.authorization_code = context.state.authorizationCode;
      return endpoints
        .post(options)
        .then((response) => {
          if (response.data.error) {
            context.commit("setIdToken", null);
            context.commit("setAccessToken", null);
            context.commit("setRefreshToken", null);
            const expires = moment();
            context.commit("setExpires", expires);
            return null;
          }
          context.dispatch("setNewToken", response.data);
          return response.data;
        })
        .catch(() => {
          context.commit("setIdToken", null);
          context.commit("setAccessToken", null);
          context.commit("setRefreshToken", null);
          const expires = moment();
          context.commit("setExpires", expires);
          return null;
        });
    },

    setNewToken(context, payload) {
      const profile = jwt(payload.id_token);
      context.commit("setIdToken", payload.id_token);
      context.commit("setAccessToken", payload.access_token);
      const expires = moment().add(53, "minutes");
      context.commit("setExpires", expires);
      context.dispatch("setProfile", profile);
      if (profile && profile.identities && profile.identities.length > 0) {
        const data = {
          UserEmail: profile.identities[0].userId,
          IsRefresh: true,
        };
        context.dispatch("saveUserLoggedInActivity", data);
      }
      context.commit("setAuthenticated", true);
    },

    getUserFromApi(context) {
      const email = context.state.profile.identities[0].userId;
      //const userUrl = `${process.env.VUE_APP_API_ROOT}api/user/GetUser?email=${encodeURIComponent(email)}&environment=${process.env.VUE_APP_CURRENT_ENVIRONMENT}`;
      const userUrl = `${
        process.env.VUE_APP_API_PROTEGO_ROOT
      }/users/${encodeURIComponent(email)}?environment=${
        process.env.VUE_APP_CURRENT_ENVIRONMENT
      }`;
      const xhr = new XMLHttpRequest();
      promisify(xhr);
      xhr.open("GET", userUrl, true);
      xhr.setRequestHeader("Authorization", context.state.accessToken);
      return new Promise((resolve) => {
        xhr.send().then(
          (response) => {
            resolve(JSON.parse(response.response));
          },
          () => {
            //reject(new Error('Error retrieving user'));
            resolve(null);
          }
        );
      });
    },

    setToken(context, payload) {
      const profile = jwt(payload.id_token);
      context.commit("setIdToken", payload.id_token);
      context.commit("setAccessToken", payload.access_token);
      context.commit("setRefreshToken", payload.refresh_token);
      const expires = moment().add(53, "minutes");
      context.commit("setExpires", expires);
      context.dispatch("setProfile", profile);
      if (profile && profile.identities && profile.identities.length > 0) {
        const data = {
          UserEmail: profile.identities[0].userId,
          IsRefresh: false,
        };
        context.dispatch("saveUserLoggedInActivity", data);
      }
      context.commit("setAuthenticated", true);
    },

    setUserData(context, user) {
      context.commit("setUserData", user);
    },
  },
};
