const state = {
    currentTab: null,
};
const mutations = {
    setCurrentTab(state, tabName) {
        state.currentTab = tabName;
    },
};
const actions = {
    setCurrentTab({ commit }, tabName) {
        commit("setCurrentTab", tabName);
    },
};
const getters = {
    getCurrentTab: (state) => state.currentTab,
};
export default {
    state,
    mutations,
    actions,
    getters
};