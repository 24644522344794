import api from "../api/product";
const options = {
  endpoint: "api/v1/products",
  baseEndpoint: "api/v1/products",
  data: {}
};
export default {
  AddProduct(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/`;
    return api.post(options);
  },
  AddRequestWithFiles(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/`;
    return api.postWithFiles(options);
  },
  UpdateProduct(id,data, token) {
    options.data = data;
    options.token = token;
    options.id=id;
    options.endpoint=`${options.baseEndpoint}`;
    return api.put(options);
  },
  Delete(id,token) {
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/${id}`;
    return api.delete(options);
  },
  GetProductFormData(id, token) {
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/${id}`;
    return api.get(options);
  },
  GetProductAuditFormData(id, token) {
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/audit/?id=${id}`;
    return api.get(options);
  },
  GetProductWorkflowStates(id, token) {
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/workflowstates/?id=${id}`;
    return api.get(options);
  },
  GetProductWorkflowHistoryStates(id, token) {
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/WorkflowHistoryStates/?id=${id}`;
    return api.get(options);
  },
  GetProductRequestWithTaskDetails(id, token) {
    options.token = token;
    options.endpoint =`${options.baseEndpoint}/RequestWithActiveTaskDetails/?id=${id}`;
    return api.get(options);
  },
  RejectRequest(data, token,level) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/${level}`;
    return api.post(options);
  },
  ApproveRequestLevel1(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/ApproveLevel1`;
    return api.post(options);
  },
  ApproveRequestLevel2(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/ApproveLevel2`;
    return api.post(options);
  },
  DownloadProductsUploadTemplate(token,data) {
    options.token =token;
    options.data=data;
    options.endpoint=`${options.baseEndpoint}/DownloadProductsUploadTemplate`;
    return api.download(options);
  },
  GetProductsById(id, token) {
    options.token = token;
    options.endpoint =`${options.baseEndpoint}/GetProductsById/?id=${id}`;
    return api.get(options);
  },
  UpdateRequestWithFiles(id,data, token) {
    options.data = data;
    options.token = token;
    options.id=id;
    options.endpoint=`${options.baseEndpoint}`;
    return api.putWithFiles(options);
  },
  
}