import api from "../api/client";
import wiretransferapi from "../api/wiretransfer";

const options = {
  endpoint: "api/v1/ProductionVendor",
  baseEndpoint: "api/v1/ProductionVendor",
  data: {}
};
export default {
  AddRequest(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/`;
    return api.post(options);
  },
  AddRequestWithFiles(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/`;
    return wiretransferapi.postWithFiles(options);
  },
  GetProductionVendorRequestWithTaskDetails(id, token) {
    options.token = token;
    options.endpoint =`${options.baseEndpoint}/RequestWithActiveTaskDetails/?id=${id}&isedit=false`;
    return api.get(options);
  },

  ApproveProdExpVendorRequestLevel1(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/ApproveLevel1`;
    return api.post(options);
  },

  RejectProdExpVendorRequestLevel1(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/RejectLevel1`;
    return api.post(options);
  },

  ApproveProdExpVendorRequestLevel2(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/ApproveLevel2`;
    return api.post(options);
  },

  RejectProdExpVendorRequestLevel2(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/RejectLevel2`;
    return api.post(options);
  },

  RejectVendorValidation(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/RejectVendorValidation`;
    return api.post(options);
  },

  ApproveProdExpVendorRequestLevel3(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/ApproveLevel3`;
    return api.post(options);
  },

  PendingVendorApproveorComplete(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/PendingVendorResponseorComplete`;
    return api.post(options);
  },

  RejectProdExpVendorRequestLevel3(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/RejectLevel3`;
    return api.post(options);
  },

  AccountingApprove(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/AccountingApprove`;
    return api.post(options);
  },

  AccountingReject(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/AccountingReject`;
    return api.post(options);
  },

  CFOApprove(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/CFOApprove`;
    return api.post(options);
  },

  CFOReject(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/CFOReject`;
    return api.post(options);
  },

  CompleteProdExpVendorRequest(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/Complete`;
    return api.post(options);
  },
  ReassignDirectorApproval(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/ReassignDirectorApproval`;
    return api.post(options);
  },
  UpdateRequest(id,data, token) {
    options.data = data;
    options.token = token;
    options.id=id;
    options.endpoint=`${options.baseEndpoint}`;
    return api.put(options);
  },
  UpdateRequestWithFiles(id,data, token) {
    options.data = data;
    options.token = token;
    options.id=id;
    options.endpoint=`${options.baseEndpoint}`;
    return wiretransferapi.putWithFiles(options);
  },
  GetFormData(id, token) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetFormData/?id=${id}`;
    return api.get(options);
  },
  GetDraftFormData(id, token) {
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/GetDraftRequest/?id=${id}`;
    return api.get(options);
  },
  GetAuditFormData(id, token) {
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/audit/?id=${id}`;
    return api.get(options);
  },
  GetWorkflowStates(id, token) {
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/workflowstates/?id=${id}`;
    return api.get(options);
  },
  GetWorkflowHistoryStates(id, token) {
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/WorkflowHistoryStates/?id=${id}`;
    return api.get(options);
  },
  GetRequestWithTaskDetails(id, token) {
    options.token = token;
    options.endpoint =`${options.baseEndpoint}/RequestWithActiveTaskDetails/?id=${id}`;
    return api.get(options);
  },
  RejectRequest(data, token,level) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/${level}`;
    return api.post(options);
  },
  ApproveRequestLevel1(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/ApproveLevel1`;
    return wiretransferapi.postWithFiles(options);
  },
  ApproveRequestLevel2(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/ApproveLevel2`;
    return api.post(options);
  },
  HoldRequestForReview(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/HoldForReview`;
    return api.post(options);
  },
  RequestForApproval(data, token,name) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/${name}`;
    return api.post(options);
  },
  DownloadProductsUploadTemplate(token,data) {
    options.token =token;
    options.data=data;
    options.endpoint=`${options.baseEndpoint}/DownloadProductsUploadTemplate`;
    return api.download(options);
  },
  GetProductsById(id, token) {
    options.token = token;
    options.endpoint =`${options.baseEndpoint}/GetProductsById/?id=${id}`;
    return api.get(options);
  },
  GetRequests(token, data) {
    options.token = token;
    options.data = data;
    options.endpoint=`${options.baseEndpoint}/List`;
    return api.list(options);
  },
  RenderUploadedFiles(token,id) {
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/RenderUploadedFiles/?id=${id}`;
    return api.get(options);
  },
  GetUsers(token, agencyName,name) {
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/${name}/?agency=${agencyName}`
    return api.get(options);
  },
  SaveAsDraft(data, token) {
    options.data = data;
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/SaveRequestAsDraft`;
    return wiretransferapi.postWithFiles(options);
  },

  DownloadUploadTemplate(token,data) {
    options.token =token;
    options.data=data;
    options.endpoint=`${options.baseEndpoint}/DownloadProductionExternalTemplate`;
    return api.download(options);
  },
  GetRequestorById(id, token) {
    options.token = token;
    options.endpoint =`${options.baseEndpoint}/GetRequestor/?id=${id}`;
    return api.get(options);
  }

}