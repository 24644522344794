import store from '@/store';

// generateVerification creates a random string for including in the OAuth2
// request, which is then validated in the response.
function generateVerification() {
  let verification = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < 32; i += 1) {
    verification += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return verification;
}


export default {

  authenticate(provider) {
    
    const domain = process.env.VUE_APP_AWS_COGNITO_USER_POOL_DOMAIN;
    const clientId = process.env.VUE_APP_AWS_COGNITO_CLIENT_ID;
    const type = 'code';
    const scope = 'openid';

    const callback = `${window.location.protocol}//${window.location.host}/callback`;
    const callbackUrl = `${window.location.pathname}`;

    store.commit('setredirectUri', callback);
    store.commit('setPathRedirectUri', callbackUrl);


    // Save the 'verification' value, so it can be verified later to prevent CSRF attacks
    const verification = generateVerification();
    store.commit('setVerification', verification);

    if (provider !== undefined) {
      // Go straight to the provider, skipping the hosted UI
      window.location.href = `https://${domain}/authorize?identity_provider=${provider}&response_type=${type}&client_id=${clientId}&redirect_uri=${callback}&state=${verification}&scope=${scope}`;
    } else {
      // Use the hosted UI
      window.location.href = `https://${domain}/login?response_type=${type}&client_id=${clientId}&redirect_uri=${callback}&state=${verification}&scope=${scope}`;
    }
  },

};
