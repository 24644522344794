import endpoints from "../../api/dropdowns";
import clientService from "../../services/client";
import lookerService from "../../services/looker";

const getDefaultState = () => {
    return {
        AgenyList: [],
        EntityList: [],
        CurrencyList: [],
        PaymentTerms: [],
        HFMandMasterClientCodes: [],
        AxLocationAxCompany: [],
        DDSMediaGroups: [],
        CountryList: [],
        StatesList: [],
        refreshedAt: null,
        saveStatus: null,
        ClientRequestInfo: {},
        ClientAgencyInfo: {},
        ClientBillingInfo: {},
        ClientMediaChannelInfo: {},
        Billingparty: [],
        user: {
            applications: [],
            email: "",
            users: [],
        },
        permissions: [],
        dataProfiles: [],
        ClientProductList: [],
        ClientCommissionInfo: [],
        Status: null,
        EditFormData: null,
        ClientInfo: {
            HfmCodeValue: null,
            MDMClientName: null,
            Entity: null,
            Agency: null,
            ClientName: null,
            TruncatedClientName: null,
            RequestClientcode: null,
            BpmAssigned: null,
            DDSMediaOfcecode: null,
            DdsAccountingofcecode: null,
            AXLocationCompany: null,
            AgencyName: null,
            EntityName: null,
            HFMCodeName: null,
            ProductUDF1: null,
            ProductUDF2: null,
            EstimateUDF1: null,
            EstimateUDF2: null,
            CheckProductUDF1: false,
            CheckProductUDF2: false,
            CheckEstimateUDF1: false,
            CheckEstimateUDF2: false,
            SpecialRequirement: null,
            ProductionBilling: false,
            IsNetworkCfoApproved: null,
            CreditVoyagerApprovalFiles: [],
            IsEditDataLoaded: false,
            IsExistingClient: null
                //Files:[]
        },

        EularInfo: {
            AddUpdate: null,
            EularGradeVal: null,
            ApprovalSourceLevel: null,
            ClientEntityName: null,
            Comments:null,
            Files: [],
            TimeOnly: "true",
            Insurance: "false",
            DdsAccountingofcecode: null,
            AXLocationCompany: null,
            DDSMediaOfcecode: null,
            EulerFiles: [],
        },

        SpecialRequirements: {
            Requirement: null,
        },

        BillingInfo: {
            ShowAORFields: false,
            BillToName: null,
            Attn: null,
            AddressLine1: null,
            AddressLine2: null,
            BillingState: null,
            BillingZipCode: null,
            Currency: null,
            DfltPayment: null,
            BillingParty: null,
            BillToClientName: null,
            Country: null,
            CountryName: null,
            StateName: null,
            CurrencyName: null,
            DefaultPaymentTermsName: null,
            IsThisCostToClient: null,
            ProgrammaticBundled: null,
            StreetAddress: null,
            AORClientCode: null,
            AORClientName: null,
            TruncatedBillingStreetAddress: null,
            AORClientAgency: null,
            AORClientAgencyName: "",
            Files: [],
        },
    };
};
const options = {
    endpoint: "/api/v1/mdm/",
};
const api = {
    endpoint: "/api/v1/mdm/",
};
// initial state
const state = getDefaultState();

// getters
const getters = {
    ClientTimeonlyCommisionDraft: (state) => {
        let timeOnly = true;
        if (
            state.ClientCommissionInfo != null &&
            state.ClientCommissionInfo.length == 1
        ) {
            let indexMediaCategory = state.ClientCommissionInfo.findIndex((val) => {
                return val.MediaCategory == "TimeOnly";
            });
            timeOnly = indexMediaCategory > -1;
        }
        return timeOnly;
    },

    ClientBillingnfoDraft: (state) => {
        let BillingInfo = {};
        BillingInfo.Attn = state.BillingInfo.Attn;
        BillingInfo.AddressLine1 = state.BillingInfo.AddressLine1;
        BillingInfo.AddressLine2 = state.BillingInfo.AddressLine2;
        BillingInfo.State = state.BillingInfo.BillingState;
        BillingInfo.ZipCode = state.BillingInfo.BillingZipCode;
        BillingInfo.Currency = state.BillingInfo.Currency;
        BillingInfo.DefaultPaymentTerms = state.BillingInfo.DfltPayment;
        BillingInfo.BillingParty = state.BillingInfo.BillingParty;
        BillingInfo.ClientBillToName = state.BillingInfo.BillToClientName;
        BillingInfo.Country = state.BillingInfo.Country;
        BillingInfo.City = state.BillingInfo.City;
        BillingInfo.ClientBillingStreetAddress = state.BillingInfo.StreetAddress;
        BillingInfo.AORClientCode = state.BillingInfo.AORClientCode;
        BillingInfo.AORClientName = state.BillingInfo.AORClientName;
        BillingInfo.AORClientAgency = state.BillingInfo.AORClientAgency;
        BillingInfo.IsThisCostToClient =
            state.BillingInfo.IsThisCostToClient == "true" ? true : false;
        BillingInfo.ProgrammaticBundled =
            state.BillingInfo.ProgrammaticBundled == "true" ? true : false;
        BillingInfo.TruncatedBillingStreetAddress =
            state.BillingInfo.TruncatedBillingStreetAddress;
        return BillingInfo;
    },

    ClientMediaCommissionDetailsDraft: (state) => {
        let commissionInfo = [];
        if (state.ClientCommissionInfo && state.ClientCommissionInfo.length > 0) {
            state.ClientCommissionInfo.forEach((mediaCategory) => {
                if (mediaCategory.MediaCategory == "TimeOnly") {
                    commissionInfo.push({
                        ID: 0,
                        ClientRequestID: 0,
                        MediaType: mediaCategory.MediaCategory,
                        MediaCategory: mediaCategory.MediaCategory,
                        CommissionPercentage: null,
                        FeeOnly: null,
                        CommissionBasis: null,
                        DefaultPaymentTerms: null,
                        ProductionBilling: false,
                    });
                } else {
                    mediaCategory.Media.forEach((media) => {
                        commissionInfo.push({
                            ID: 0,
                            ClientRequestID: 0,
                            MediaType: media.MediaType,
                            MediaCategory: mediaCategory.MediaCategory,
                            CommissionPercentage: parseFloat(media.CommissionPercentage),
                            FeeOnly: media.FeeOnly == "Yes" ? true : false,
                            CommissionBasis: media.CommissionBasis,
                            DefaultPaymentTerms: media.DefaultPaymentTerms,
                            ProductionBilling: false,
                        });
                    });
                }
            });
        } else {
            commissionInfo.push({
                ID: 0,
                ClientRequestID: 0,
                MediaType: "",
                MediaCategory: "",
                CommissionPercentage: 0,
                FeeOnly: false,
                CommissionBasis: null,
                DefaultPaymentTerms: null,
                ProductionBilling: true,
            });
            window.console.log(`executed prod billing section`);
        }
        window.console.log(`commission info length  ${commissionInfo.length}`);
        return commissionInfo;
    },

    clientProductListDraft: (state) => {
        window.console.clear();
        window.console.log(
            "Product List2" + JSON.stringify(state.ClientProductList)
        );
        let ProductInfo = [];
        if (state.ClientProductList && state.ClientProductList.length > 0) {
            state.ClientProductList.forEach((val) => {
                if (val.ProductName != null && val.ProductCode != null) {
                    let productBillingAddress = {};
                    if (val.AddedBillingException) {
                        productBillingAddress.Attn = val.ProductBillingAddress.Attn;
                        productBillingAddress.BillToName =
                            val.ProductBillingAddress.BillToName;
                        productBillingAddress.AddressLine1 =
                            val.ProductBillingAddress.AddressLine1;
                        productBillingAddress.AddressLine2 =
                            val.ProductBillingAddress.AddressLine2;
                        productBillingAddress.State = val.ProductBillingAddress.State;
                        productBillingAddress.ZipCode = val.ProductBillingAddress.ZipCode;
                        productBillingAddress.Country = val.ProductBillingAddress.Country;
                        productBillingAddress.City = val.ProductBillingAddress.City;
                        productBillingAddress.BillingStreetAddress =
                            val.ProductBillingAddress.StreetAddress;
                        productBillingAddress.TruncatedBillingStreetAddress =
                            val.ProductBillingAddress.TruncatedBillingStreetAddress;
                    } else {
                        productBillingAddress = null;
                    }
                    let productMediaDetails = [];
                    if (val.Media && val.Media.length > 0) {
                        val.Media.forEach((media) => {
                            productMediaDetails.push({
                                ID: 0,
                                ProductRequestID: 0,
                                ...media,
                            });
                        });
                    }

                    let commissionException = {
                        FeelOnly: null,
                        CommissionBasis: null,
                        CommissionPercentage: null,
                        TimeOnly: null,
                    };
                    if (val.AddedCommissionException) {
                        commissionException.TimeOnly =
                            val.CommissionInfo.TimeOnly != null &&
                            val.CommissionInfo.TimeOnly == "true" ?
                            true :
                            false;
                        commissionException.FeeOnly =
                            val.CommissionInfo.FeeOnly != null ?
                            val.CommissionInfo.FeeOnly == "Yes" ?
                            true :
                            false :
                            null;
                        commissionException.CommissionBasis =
                            val.CommissionInfo.CommissionBasis;
                        commissionException.CommissionPercentage = parseFloat(
                            val.CommissionInfo.CommissionPercentage
                        );
                    }
                    ProductInfo.push({
                        ProductCommissionDetails: productMediaDetails,
                        ...commissionException,
                        ProductName: val.ProductName.toString(),
                        ProductCode: val.ProductCode.toString(),
                        TruncatedProductName: val.TruncatedProductName,
                        ProductBillingAddress: productBillingAddress,
                    });
                }
            });
        }
        return ProductInfo;
    },

    billingparties: (state) => {
        return state.Billingparty;
    },
    data: (state) => {
        return state.ClientRequestInfo;
    },
    clientProductList: (state) => {
        return state.ClientProductList;
    },
    clientCommissionInfo: (state) => {
        return state.ClientCommissionInfo;
    },
    ClientBasicDetails: (state) => {
        return state.ClientInfo;
    },

    SpecialRequirement: (state) => {
        return state.SpecialRequirements;
    },

    Status: (state) => {
        return state.Status;
    },
    SubmittedFormData: (state) => {
        return state.EditFormData;
    },
    ClientEularInfo: (state) => {
        return state.EularInfo;
    },
    ClientEularInfoFiles: (state) => {
        return state.EularInfo.Files;
    },

    ClientBillingInformations: (state) => {
        return state.BillingInfo;
    },
    ClientBillingInformationsDefaultPaymentTerms: (state) => {
        return state.BillingInfo ? state.BillingInfo.DfltPayment : null;
    },
    AgencyList: (state) => {
        return state.AgencyGroups;
    },
    PaymentTerms: (state) => {
        return state.PaymentTerms;
    },
};

// const options = {
//   endpoint: "users"
// };

// actions
const actions = {
    getClientDraftData(st, id) {
        clientService
            .GetClientDraftData(id, st.rootState.auth.idToken)
            .then((res) => {
                if (res.data.GUID && res.data.GUID != null && res.data.GUID != "") {
                    window.localStorage.setItem("UploadfileId", res.data.GUID);
                }
                st.commit("loadEditFormData", res.data);
                st.commit("setRequestStatus", res.data.Status);
                st.commit("loadClientDetails", res.data);
                st.commit("loadBillingInfo", res.data.BillingInfo);
                st.commit("loadClientInfo", res.data);
                st.commit("loadEulerGradeInfo", res.data);
                st.commit("loadProductInfo", res.data.ProductDetails);
                return res.data;
            })
            .catch((error) => {
                return error;
            });
        clientService
            .RenderDraftUploadedFiles(st.rootState.auth.idToken, id)
            .then((res) => {
                st.commit("loadEularFiles", res.data);
                st.commit("loadScreenshotFiles", res.data);
                st.commit("loadCreditVoyagerApprovalFiles", res.data);
            });
    },
    async getClientFormData(st, id) {
        try {
            const res = await clientService.GetClientFormData(id, st.rootState.auth.idToken);
            st.commit("loadEditFormData", res.data);
            st.commit("setRequestStatus", res.data.Status);
            st.commit("loadClientDetails", res.data);
            st.commit("loadBillingInfo", res.data.BillingInfo);
            st.commit("loadClientInfo", res.data);
            st.commit("loadEulerGradeInfo", res.data);
            st.commit("loadProductInfo", res.data.ProductDetails);
            await clientService.RenderUploadedFiles(st.rootState.auth.idToken, id)
                .then((filesRes) => {
                    st.commit("loadEularFiles", filesRes.data);
                    st.commit("loadScreenshotFiles", filesRes.data);
                    st.commit("loadCreditVoyagerApprovalFiles", filesRes.data);
                });
            return res.data;
        } catch (error) {
            throw error;
        }
    },
    GetClientAuditFormData(st, id) {
        return clientService.GetClientAuditFormData(id, st.rootState.auth.idToken);
    },
    GetClientWorkflowStates(st, id) {
        return clientService.GetClientWorkflowStates(id, st.rootState.auth.idToken);
    },
    GetClientWorkflowHistoryStates(st, id) {
        return clientService.GetClientWorkflowHistoryStates(
            id,
            st.rootState.auth.idToken
        );
    },

    GetICWorkflowHistoryStates(st, id) {
        return clientService.GetICWorkflowHistoryStates(
            id,
            st.rootState.auth.idToken
        );
    },

    GetClientRequestWithTaskDetails(st, id) {
        return clientService.GetClientRequestWithTaskDetails(
            id,
            st.rootState.auth.idToken
        );
    },
    getDropdownList(st, name) {
        st.commit("progress/setProcessing", name, {
            root: true,
        });
        return endpoints.getDropdownList(name).then(
            (response) => {
                st.commit("progress/removeProcessing", name, {
                    root: true,
                });
                return response;
            },
            () => {
                st.commit("progress/removeProcessing", name, {
                    root: true,
                });
            }
        );
    },
    getPaymentTerms(st) {
        let name = "PaymentTerms";
        st.commit("progress/setProcessing", name, {
            root: true,
        });
        options.endpoint = `${api.endpoint}PaymentTerms`;
        options.token = st.rootState.auth.idToken;
        return endpoints.getPaymentTerms(options).then(
            (response) => {
                st.commit("progress/removeProcessing", name, {
                    root: true,
                });
                st.commit("savePaymentTerms", response.data);
                return response;
            },
            () => {
                st.commit("progress/removeProcessing", name, {
                    root: true,
                });
            }
        );
    },
    getAgencyList(st) {
        // const vm = this._vm;
        st.commit("progress/setProcessing", "agencydropdown", {
            root: true,
        });
        return endpoints.getAgencyList().then(
            (response) => {
                const agencyList = response.data;
                st.commit("assignAgencyDropdown", agencyList);
                st.commit("progress/removeProcessing", "agencydropdown", {
                    root: true,
                });
                return agencyList;
            },
            () => {
                st.commit("progress/removeProcessing", "agencydropdown", {
                    root: true,
                });
            }
        );
    },
    AddClient(st, data) {
        return clientService.AddClient(data, st.rootState.auth.idToken);
    },

    AddIC(st, data) {
        return clientService.AddIC(data, st.rootState.auth.idToken);
    },

    GetAllInterCompanyAgencies(st, data) {
        return clientService.GetAllInterCompanyAgencies(
            data,
            st.rootState.auth.idToken
        );
    },

    GetADEmpDetails(st, data) {
        return clientService.GetADEmpDetails(data, st.rootState.auth.idToken);
    },

    PostProfileUnlockRequest(st, data) {
        return clientService.ProfileUnlockRequest(data, st.rootState.auth.idToken);
    },

    AddProfileWithfile(st, data) {
        return clientService.AddProfileWithfile(data, st.rootState.auth.idToken);
    },

    GetAllProfileUnlockRequests(st, data) {
        return clientService.GetAllProfileUnlockRequests(
            data,
            st.rootState.auth.idToken
        );
    },

    GetProfileUnlockRequestWithTaskDetails(st, obj) {
        return clientService.GetProfileUnlockRequestWithTaskDetails(
            obj,
            st.rootState.auth.idToken
        );
    },

    ApproveProfileUnlockRequestLevel1(st, data) {
        return clientService.ApproveProfileUnlockRequestLevel1(
            data,
            st.rootState.auth.idToken
        );
    },

    ApproveProfileUnlockRequestLevel2(st, data) {
        return clientService.ApproveProfileUnlockRequestLevel2(
            data,
            st.rootState.auth.idToken
        );
    },

    //ProfileUnlockComplete
    ApproveProfileUnlockRequestComplete(st, data) {
        return clientService.ApproveProfileUnlockRequestComplete(
            data,
            st.rootState.auth.idToken
        );
    },

    BulkRelock(st, data) {
        return clientService.BulkRelock(
            data,
            st.rootState.auth.idToken
        );
    },

    RejectProfileUnlockRequestLevel1(st, data) {
        return clientService.RejectProfileUnlockRequestLevel1(
            data,
            st.rootState.auth.idToken,
            "RejectLevel1"
        );
    },

    RejectProfileUnlockRequestLevel2(st, data) {
        return clientService.RejectProfileUnlockRequestLevel2(
            data,
            st.rootState.auth.idToken,
            "RejectLevel2"
        );
    },

    DownloadProfileFile(st, data) {
        return clientService.DownloadProfileFile(st.rootState.auth.idToken, data);
    },

    GetProfileAuditFormData(st, id) {
        return clientService.GetProfileAuditFormData(id, st.rootState.auth.idToken);
    },

    GetProfileWorkflowHistoryStates(st, id) {
        return clientService.GetProfileWorkflowHistoryStates(
            id,
            st.rootState.auth.idToken
        );
    },

    UpdatProfileUnlock(st, { id, data }) {
        return clientService.UpdateProfileUnlock(
            id,
            data,
            st.rootState.auth.idToken
        );
    },

    UpdateProfileWithfile(st, { id, data }) {
        return clientService.UpdateProfileWithfile(
            id,
            data,
            st.rootState.auth.idToken
        );
    },

    DeleteProfileUnlock(st, id) {
        return clientService.DeleteProfileUnlock(st.rootState.auth.idToken, id);
    },

    UpdateIC(st, data) {
        return clientService.UpdateIC(data, st.rootState.auth.idToken);
    },

    GetIC(st, options) {
        return clientService.GetIC(st.rootState.auth.idToken, options);
    },

    GetCompleteICRequest(st, id) {
        return clientService.GetCompleteICRequest(st.rootState.auth.idToken, id);
    },

    GetICRequestWithTaskDetails(st, id) {
        return clientService.GetICRequestWithTaskDetails(
            id,
            st.rootState.auth.idToken
        );
    },

    ApproveICRequestLevel1(st, data) {
        return clientService.ApproveICRequestLevel1(
            data,
            st.rootState.auth.idToken
        );
    },

    ApproveICRequestLevel2(st, data) {
        return clientService.ApproveICRequestLevel2(
            data,
            st.rootState.auth.idToken
        );
    },

    RejectRequestICLevel1(st, data) {
        return clientService.RejectICRequest(
            data,
            st.rootState.auth.idToken,
            "RejectLevel1"
        );
    },

    RejectRequestICLevel2(st, data) {
        return clientService.RejectICRequest(
            data,
            st.rootState.auth.idToken,
            "RejectLevel2"
        );
    },

    RejectRequestICLevelFinal(st, data) {
        return clientService.RejectICRequest(
            data,
            st.rootState.auth.idToken,
            "RejectatFinalLevel"
        );
    },

    CompleteICRequest(st, data) {
        return clientService.CompleteICRequest(data, st.rootState.auth.idToken);
    },

    CompleteIC(st, data) {
        return clientService.Complete(data, st.rootState.auth.idToken);
    },

    GetICAuditFormData(st, id) {
        return clientService.GetICAuditFormData(id, st.rootState.auth.idToken);
    },

    UpdateClient(st, { id, data }) {
        return clientService.UpdateClient(id, data, st.rootState.auth.idToken);
    },
    GetRequests(st, options) {
        return clientService.GetRequests(st.rootState.auth.idToken, options);
    },
    ApproveRequest(st, data) {
        return clientService.ApproveRequest(data, st.rootState.auth.idToken);
    },
    ApproveRequestLevel1(st, data) {
        return clientService.ApproveRequestLevel1(data, st.rootState.auth.idToken);
    },
    ApproveRequestLevel2(st, data) {
        return clientService.ApproveRequestLevel2(data, st.rootState.auth.idToken);
    },
    RejectRequestLevel1(st, data) {
        return clientService.RejectRequest(
            data,
            st.rootState.auth.idToken,
            "RejectLevel1"
        );
    },
    RejectRequestLevel2(st, data) {
        return clientService.RejectRequest(
            data,
            st.rootState.auth.idToken,
            "RejectLevel2"
        );
    },
    RejectRequestIC(st, data) {
        return clientService.RejectRequest(
            data,
            st.rootState.auth.idToken,
            "RejectICRequest"
        );
    },

    RejectRequestatLast(st, data) {
        return clientService.RejectRequestatLast(data, st.rootState.auth.idToken);
    },

    CompleteRequest(st, data) {
        return clientService.CompleteRequest(data, st.rootState.auth.idToken);
    },
    Complete(st, data) {
        return clientService.Complete(data, st.rootState.auth.idToken);
    },
    TriggerTask(st, { data, method }) {
        return clientService.TriggerTask(data, st.rootState.auth.idToken, method);
    },
    DownloadList(st, data) {
        return clientService.DownloadList(st.rootState.auth.idToken, data);
    },
    RenderUploadedFiles(st, id) {
        return clientService.RenderUploadedFiles(st.rootState.auth.idToken, id);
    },
    RenderDraftUploadedFiles(st, id) {
        return clientService.RenderDraftUploadedFiles(
            st.rootState.auth.idToken,
            id
        );
    },
    DeleteUploadedFile(st, data) {
        return clientService.DeleteUploadedFile(st.rootState.auth.idToken, data);
    },
    DownloadFile(st, data) {
        return clientService.DownloadFile(st.rootState.auth.idToken, data);
    },
    GetMasterClient(st) {
        return lookerService.GetMasterClient(st.rootState.auth.idToken);
    },
    GetAccountingOfficeCodes(st, data) {
        return lookerService.GetAccountingOfficeCodes(st.rootState.auth.idToken, data);
    },
    GetMediaOfficeCodes(st, data) {
        return lookerService.GetMediaOfficeCodes(st.rootState.auth.idToken, data);
    },
    GetBillingParty(st, data) {
        return lookerService.GetBillingParty(st.rootState.auth.idToken, data);
    },
    DownloadICFile(st, data) {
        return clientService.DownloadICFile(st.rootState.auth.idToken, data);
    },
    DeleteIC(st, id) {
        return clientService.DeleteIC(st.rootState.auth.idToken, id);
    },
    Delete(st, id) {
        return clientService.Delete(st.rootState.auth.idToken, id);
    },
    DeleteDraft(st, id) {
        return clientService.DeleteDraft(st.rootState.auth.idToken, id);
    },

    DownloadProductsUploadTemplate(st, data) {
        return clientService.DownloadProductsUploadTemplate(
            st.rootState.auth.idToken,
            data
        );
    },

    SaveClientDraft(st, draftRequestId) {
        window.console.log(JSON.stringify(draftRequestId));
        var DraftObj = {};
        DraftObj.DraftRequestID =
            draftRequestId != null && !isNaN(draftRequestId) ?
            parseInt(draftRequestId) :
            null;
        if (st.getters.ClientBasicDetails.HFMCodeName.indexOf("~") > -1) {
            DraftObj.HFMCode = st.getters.ClientBasicDetails.HfmCodeValue.split(
                "~"
            )[1];
            DraftObj.HFMCodeID = st.getters.ClientBasicDetails.HfmCodeValue.split(
                "~"
            )[0];
        } else {
            DraftObj.HFMCode = st.getters.ClientBasicDetails.HfmCodeValue;
            DraftObj.HFMCodeID = null;
        }
        DraftObj.EulerGrade = parseInt(st.getters.ClientEularInfo.EularGradeVal);
        DraftObj.IsMediaSpendExceedsInsuranceCoverage =
            st.getters.ClientEularInfo.Insurance == "true" ? true : false;
        DraftObj.ApprovalSource = st.getters.ClientEularInfo.ApprovalSourceLevel;
        DraftObj.ClientEntityNamePerAgreement =
            st.getters.ClientEularInfo.ClientEntityName;
        DraftObj.Comments =
            st.getters.ClientEularInfo.Comments;
        DraftObj.MDMClientName = "Hard Coded"; //this.clientinfopageone.MDMClientName;
        DraftObj.ClientName = st.getters.ClientBasicDetails.ClientName;
        DraftObj.RequestedClientCode =
            st.getters.ClientBasicDetails.RequestClientcode;
        DraftObj.IsExistingClient = st.getters.ClientBasicDetails.IsExistingClient == "true" ? true : false;
        DraftObj.DDSAccountingOfficeCode =
            st.getters.ClientBasicDetails.DdsAccountingofcecode;
        DraftObj.DDSMediaOfficeCode =
            st.getters.ClientBasicDetails.DDSMediaOfcecode;
        DraftObj.AXLocationOrCompany =
            st.getters.ClientBasicDetails.AXLocationCompany;

        DraftObj.ProductUDF1 = st.getters.ClientBasicDetails.ProductUDF1;
        DraftObj.ProductUDF2 = st.getters.ClientBasicDetails.ProductUDF2;
        DraftObj.EstimateUDF1 = st.getters.ClientBasicDetails.EstimateUDF1;
        DraftObj.EstimateUDF2 = st.getters.ClientBasicDetails.EstimateUDF2;

        DraftObj.Entity = st.getters.ClientBasicDetails.Entity;
        DraftObj.Agency = st.getters.ClientBasicDetails.Agency;
        DraftObj.TruncatedClientName =
            st.getters.ClientBasicDetails.TruncatedClientName;
        DraftObj.AddOrUpdateEulerGrade =
            st.getters.ClientEularInfo.TimeOnly == "true" ? true : false;
        DraftObj.BPMAssigned = st.getters.ClientBasicDetails.BpmAssigned;
        DraftObj.CreatedDate = new Date();
        DraftObj.UpdatedDate = new Date();
        DraftObj.Guid = localStorage.getItem("UploadfileId");
        DraftObj.BillingInfo = st.getters.ClientBillingnfoDraft;
        DraftObj.ClientRequestApprovalDetails = [];
        DraftObj.ClientMediaCommissionDetails = [];
        DraftObj.ProductDetails = st.getters.clientProductListDraft;
        DraftObj.ProductionBilling =
            st.getters.ClientBasicDetails.ProductionBilling == "true" ||
            st.getters.ClientBasicDetails.ProductionBilling == true ?
            true :
            false;
        DraftObj.ClientMediaCommissionDetails =
            st.getters.ClientMediaCommissionDetailsDraft;

        if (
            DraftObj.ClientMediaCommissionDetails == null ||
            DraftObj.ClientMediaCommissionDetails.length < 1
        ) {
            DraftObj.TimeOnly = st.getters.ClientTimeonlyCommisionDraft;
        }
        DraftObj.IsClientICId =
            localStorage.getItem("clienticId") == "" ?
            0 :
            parseInt(localStorage.getItem("clienticId"));
        //localStorage.setItem('clienticId','');
        //DraftObj.SpecialRequirement=st.SpecialRequirement==""?st.clientinfopageone.SpecialRequirement:this.SpecialRequirement;
        // window.console.log(JSON.stringify(DraftObj));

        DraftObj.ClientEntityNamePerAgreement =
            st.getters.ClientEularInfo.ClientEntityName;
        DraftObj.Files = [];

        let eulerFiles = st.getters.ClientEularInfo.EulerFiles;
        if (eulerFiles != null && eulerFiles.length > 0) {
            eulerFiles.forEach(eulerFile => {
                DraftObj.Files.push({ Category: `EulerFiles`, Name: eulerFile.Name || eulerFile.name })
            });
        }
        let screenshotFiles = st.getters.ClientBillingInformations.Files;
        if (screenshotFiles != null && screenshotFiles.length > 0) {
            screenshotFiles.forEach(screenshotFile => {
                DraftObj.Files.push({ Category: `ScreenshotFiles`, Name: screenshotFile.Name || screenshotFile.name })
            });
        }
        let creditVoyagerApprovalFiles = st.getters.ClientBasicDetails.CreditVoyagerApprovalFiles;
        if (creditVoyagerApprovalFiles != null && creditVoyagerApprovalFiles.length > 0) {
            creditVoyagerApprovalFiles.forEach(creditVoyagerApprovalFile => {
                DraftObj.Files.push({ Category: `CreditVoyagerApprovalFiles`, Name: creditVoyagerApprovalFile.Name || creditVoyagerApprovalFile.name })
            });
        }
        let formData = new FormData();
        formData.append("request", JSON.stringify(DraftObj));

        for (let i = 0; i < eulerFiles.length; i++) {
            let file = eulerFiles[i];
            formData.append(`EulerFiles`, file.file);
        }
        for (let j = 0; j < screenshotFiles.length; j++) {
            let file = screenshotFiles[j];
            formData.append(`ScreenshotFiles`, file.file);
        }
        for (let k = 0; k < creditVoyagerApprovalFiles.length; k++) {
            let file = creditVoyagerApprovalFiles[k];
            formData.append(`CreditVoyagerApprovalFiles`, file.file);
        }
        return clientService.SaveClientDraft(st.rootState.auth.idToken, formData);
    },
    SaveInterCompanyDraft(st, data) {
        return clientService.SaveInterCompanyDraft(st.rootState.auth.idToken, data);
    },
    GetClientPropertyByCode(st, { clientCode, propertyName }) {
        return clientService.GetClientPropertyByCode(
            st.rootState.auth.idToken,
            clientCode,
            propertyName
        );
    },
};

// mutations
const mutations = {
    addEularFiles(st, data) {
        st.EularInfo.Files = [];
        //st.ClientInfo.Files=[];

        if (data != null) {
            data.forEach((s) => {
                st.EularInfo.Files.push(s.name);
                //st.ClientInfo.Files.push(s);
            });
        }
    },
    loadEularFiles(st, data) {
        st.EularInfo.EulerFiles = [];
        if (data != null && data.length > 0) {
            data.filter(file => file.Category == "EulerFiles").forEach((eulerFile) => {
                st.EularInfo.EulerFiles.push(eulerFile);
            });
        }
    },
    loadScreenshotFiles(st, data) {
        st.BillingInfo.Files = [];
        if (data != null && data.length > 0) {
            data.filter(file => file.Category == "ScreenshotFiles").forEach(screenshotFile => {
                st.BillingInfo.Files.push(screenshotFile);
            });
        }
    },
    loadCreditVoyagerApprovalFiles(st, data) {
        st.ClientInfo.CreditVoyagerApprovalFiles = [];
        if (data != null && data.length > 0) {
            data.filter(file => file.Category == "CreditVoyagerApprovalFiles").forEach(creditVoyagerApprovalFile => {
                st.ClientInfo.CreditVoyagerApprovalFiles.push(creditVoyagerApprovalFile);
            });
        }
        if (st.ClientInfo.CreditVoyagerApprovalFiles != null && st.ClientInfo.CreditVoyagerApprovalFiles.length > 0) {
            st.ClientInfo.IsNetworkCfoApproved = 'true';
        }
        st.ClientInfo.IsEditDataLoaded = true;
    },
    loadBillingparty(st, data) {
        st.Billingparty = [];

        //Object.assign(state,getDefaultState());
        // if(st.BillingParty.length > 0)
        // {
        //   alert('remove everything');
        //st.Billingparty.splice(0,st.BillingParty.length);
        // }
        data.forEach((s) => {
            st.Billingparty.push({...s });
        });
    },
    loadEditFormData(st, data) {
        st.EditFormData = data;
    },

    specialrequirement(st, data) {
        st.SpecialRequirements = data;
    },

    loadEulerGradeInfo(st, data) {
        st.EularInfo.TimeOnly = data.AddOrUpdateEulerGrade.toString();
        let IsTimeOnlyNo = st.EularInfo.TimeOnly != "true";
        st.EularInfo.EularGradeVal = !IsTimeOnlyNo ?
            data.EulerGrade.toString() :
            null;
        st.EularInfo.ApprovalSourceLevel = !IsTimeOnlyNo ?
            data.ApprovalSource != null ?
            data.ApprovalSource.toString() :
            null :
            null;
        st.EularInfo.ClientEntityName = !IsTimeOnlyNo ?
            data.ClientEntityNamePerAgreement :
            null;
        st.EularInfo.Comments = !IsTimeOnlyNo ?
            data.Comments :
            null;
        st.EularInfo.Insurance = !IsTimeOnlyNo ?
            data.IsMediaSpendExceedsInsuranceCoverage != null ?
            data.IsMediaSpendExceedsInsuranceCoverage.toString() :
            null :
            null;
    },
    loadProductInfo(st, data) {
        data.forEach((s) => {
            let addedCommissionException =
                s.FeeOnly != null || s.CommissionBasisID != null || s.TimeOnly != null;
            let commissionInfo = {};
            commissionInfo.FeeOnly = s.FeeOnly == true ? "Yes" : null;
            commissionInfo.TimeOnly = s.TimeOnly == true ? "true" : null;
            commissionInfo.CommissionBasis =
                s.CommissionBasisID != null ?
                s.CommissionBasisID == 1 ?
                "Net" :
                "Gross" :
                null;
            commissionInfo.CommissionPercentage = s.CommissionPercentage;
            let billingAddress = {};
            if (s.ProductBillingAddress != null) {
                billingAddress = {
                    ...s.ProductBillingAddress,
                    StreetAddress: s.ProductBillingAddress.BillingStreetAddress,
                    TruncatedBillingStreetAddress: s.ProductBillingAddress.TruncatedBillingStreetAddress,
                };
            }
            st.ClientProductList.push({
                ProductName: s.ProductName,
                ProductCode: s.ProductCode,
                TruncatedProductName: s.TruncatedProductName,
                CommissionInfo: commissionInfo,
                AddedBillingException: s.ProductBillingAddress != null &&
                    s.ProductBillingAddress.Country != null,
                ProductBillingAddress: billingAddress,
                Media: s.ProductCommissionDetails,
                AddedCommissionException: addedCommissionException,
            });
        });
    },
    loadClientInfo(st, data) {
        //st.ClientForm.EularGradeVal=parseInt(data.EulerGrade);
        //st.ClientForm.ClientEntityName=data.ClientEntityNamePerAgreement;
        st.ClientInfo.HfmCodeValue = data.HFMCode;
        st.ClientInfo.Entity = data.Entity;
        st.ClientInfo.Agency = data.Agency;
        st.ClientInfo.MDMClientName = data.MDMClientName;
        st.ClientInfo.ClientName = data.ClientName;
        st.ClientInfo.RequestClientcode = data.RequestedClientCode;
        st.ClientInfo.IsExistingClient = data.IsExistingClient;
        st.ClientInfo.DdsAccountingofcecode = data.DDSAccountingOfficeCode;
        st.ClientInfo.DDSMediaOfcecode = data.DDSMediaOfficeCode;
        st.ClientInfo.AXLocationCompany = data.AXLocationOrCompany;
        st.ClientInfo.TruncatedClientName = data.TruncatedClientName;
        st.ClientInfo.AddOrUpdateEulerGrade = true;
        st.ClientInfo.BpmAssigned = data.BPMAssigned;
        st.ClientInfo.EntityName = data.EntityName;
        st.ClientInfo.AgencyName = data.AgencyName;
        st.ClientInfo.HFMCodeName = data.HFMCode;
        st.ClientInfo.ProductUDF1 = data.ProductUDF1;
        st.ClientInfo.ProductUDF2 = data.ProductUDF2;
        st.ClientInfo.EstimateUDF1 = data.EstimateUDF1;
        st.ClientInfo.EstimateUDF2 = data.EstimateUDF2;

        st.ClientInfo.CheckProductUDF1 =
            data.ProductUDF1 != null && data.ProductUDF1.length > 0;
        st.ClientInfo.CheckProductUDF2 =
            data.ProductUDF2 != null && data.ProductUDF2.length > 0;
        st.ClientInfo.CheckEstimateUDF1 =
            data.EstimateUDF1 != null && data.EstimateUDF1.length > 0;
        st.ClientInfo.CheckEstimateUDF2 =
            data.EstimateUDF2 != null && data.EstimateUDF2.length > 0;
        st.ClientInfo.SpecialRequirement = data.SpecialRequirement;
        st.ClientInfo.ProductionBilling = data.ProductionBilling;
        if (
            data.ClientMediaCommissionDetails != null &&
            data.ClientMediaCommissionDetails.length > 0
        ) {
            let prodBillingIndex = data.ClientMediaCommissionDetails.findIndex(
                (s) => {
                    return s.ProductionBilling == true;
                }
            );
            st.ClientInfo.ProductionBilling = prodBillingIndex > -1;
        }
    },
    loadBillingInfo(st, data) {
        st.BillingInfo.Attn = data.Attn;
        st.BillingInfo.AddressLine1 = data.AddressLine1;
        st.BillingInfo.AddressLine2 = data.AddressLine2;
        st.BillingInfo.BillingStatee = data.State;
        st.BillingInfo.BillingZipCode = data.ZipCode;
        st.BillingInfo.Currency = data.Currency;
        st.BillingInfo.DfltPayment = data.DefaultPaymentTerms;
        st.BillingInfo.BillingParty = data.BillingParty;
        st.BillingInfo.BillToClientName = data.ClientBillToName;
        st.BillingInfo.Country = data.Country;
        st.BillingInfo.CountryName = data.CountryName;
        st.BillingInfo.CurrencyName = data.CurrencyName;
        st.BillingInfo.StateName = data.StateName;
        st.BillingInfo.DefaultPaymentTermsName = data.DefaultPaymentTermsName;
        st.BillingInfo.StreetAddress = data.ClientBillingStreetAddress;
        st.BillingInfo.TruncatedBillingStreetAddress =
            data.ClientBillingTruncatedStreetAddress;
        st.BillingInfo.City = data.City;
        st.BillingInfo.BillingState = data.State;
        st.BillingInfo.IsThisCostToClient = data.IsThisCostToClient;
        st.BillingInfo.ProgrammaticBundled = data.ProgrammaticBundled;
        st.BillingInfo.AORClientCode = data.AORClientCode;
        st.BillingInfo.AORClientName = data.AORClientName;
        st.BillingInfo.AORClientAgency = data.AORClientAgency;
        st.BillingInfo.AORClientAgencyName = data.AORClientAgencyName;
    },
    setRequestStatus(st, status) {
        st.Status = status;
    },
    loadClientDetails(st, data) {
        if (!st.ClientInfo.ProductionBilling) {
            let distinctMediaCategory = [
                ...new Set(
                    data.ClientMediaCommissionDetails.map((commission) => {
                        return commission.MediaCategory;
                    })
                ),
            ];
            distinctMediaCategory.forEach((mediaCategory) => {
                st.ClientCommissionInfo.push({
                    MediaCategory: mediaCategory,
                    Media: [],
                });
            });
            st.ClientCommissionInfo.forEach((mediaCategory) => {
                let mediaDetails = data.ClientMediaCommissionDetails.filter((media) => {
                    return media.MediaCategory == mediaCategory.MediaCategory;
                }).map(function(s) {
                    return {
                        MediaType: s.MediaType,
                        FeeOnly: s.FeeOnly == 1 ? "Yes" : "No",
                        CommissionBasis: s.CommissionBasis,
                        CommissionPercentage: s.CommissionPercentage,
                        DefaultPaymentTerms: s.DefaultPaymentTerms,
                    };
                });
                if (mediaDetails) {
                    mediaCategory.Media = mediaDetails;
                }
            });
        }
    },
    savePaymentTerms(st, paymentTerms) {
        st.PaymentTerms = paymentTerms;
    },
    reset(st) {
        const s = getDefaultState();
        Object.keys(st).forEach((d) => {
            st[d] = s[d];
        });
        // Object.assign(st, getDefaultState());
    },
    AddProductToList(st, product) {
        st.ClientProductList.push({
            ...product,
        });
    },
    SaveProductToList(st, product, index) {
        st.ClientProductList[index] = product;
    },
    RemoveProduct(st, index) {
        st.ClientProductList.splice(index, 1);
    },
    RemoveAllCommission(st) {
        st.ClientCommissionInfo.splice(0, st.ClientCommissionInfo.length);
    },
    AddMediaToList(st, media, timeonly) {
        if (media && media.length > 0) {
            media.forEach(function(value) {
                let mediaTypeFound = st.ClientCommissionInfo.findIndex((val) => {
                    return val.MediaCategory == value.MediaCategory;
                });
                if (mediaTypeFound != -1) {
                    value.Media.forEach((mediaInside) => {
                        st.ClientCommissionInfo[mediaTypeFound].Media.push(mediaInside);
                    });
                } else {
                    st.ClientCommissionInfo.push(value);
                }
            });
        } else if (timeonly) {
            st.ClientCommissionInfo.push({
                Name: "Time Only",
            });
        }
    },

    NewClientInfo(st, clientinformation) {
        st.ClientInfo = clientinformation;
    },
    NewClientEularInfo(st, clientinformation) {
        st.EularInfo = clientinformation;
    },

    NewClieSpecialrequirement(st, clientinformation) {
        st.SpecialReq = clientinformation;
    },

    NewClientBillingInfo(st, clientinformation) {
        st.BillingInfo = clientinformation;
    },

    RemoveMedia(st, parentIndex, index, name) {
        let itemIndex = st.ClientCommissionInfo[parentIndex].Media.findIndex(
            (val) => {
                return val.Name == name;
            }
        );
        st.ClientCommissionInfo[parentIndex].Media.splice(itemIndex, 1);
    },
    RemoveMediaType(st, index) {
        st.ClientCommissionInfo.splice(index, 1);
    },

    setRequestor(st, value) {
        st.ClientRequestInfo.Requestor = value;
    },
    assignAgencyDropdown(st, list) {
        st.AgenyList = list;
    },
    assignEntityDropdown(st, list) {
        st.EntityList = list;
    },
    assignHFMDropdown(st, list) {
        st.HFMandMasterClientCodes = list;
    },
    updateClientRequestInfo(st, clientInfo) {
        st.ClientRequestInfo = clientInfo;
    },
    updateClientAgencyInfo(st, clientInfo) {
        st.ClientAgencyInfo = clientInfo;
    },
    updateClientBillingInfo(st, clientInfo) {
        st.ClientBillingInformation = clientInfo;
    },
    updateClientMediaChannelInfo(st, clientInfo) {
        st.ClientMediaChannelInfo = clientInfo;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};