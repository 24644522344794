import api from "../api/client";
import wiretransferapi from "../api/wiretransfer";

const options = {
    endpoint: "api/v1/WireTransfers",
    baseEndpoint: "api/v1/WireTransfers",
    data: {}
};
export default {
    AddRequest(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/`;
        return api.post(options);
    },
    AddRequestWithFiles(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/`;
        return wiretransferapi.postWithFiles(options);
    },
    UpdateRequest(id, data, token) {
        options.data = data;
        options.token = token;
        options.id = id;
        options.endpoint = `${options.baseEndpoint}`;
        return api.put(options);
    },
    UpdateRequestWithFiles(id, data, token) {
        options.data = data;
        options.token = token;
        options.id = id;
        options.endpoint = `${options.baseEndpoint}`;
        return wiretransferapi.putWithFiles(options);
    },
    GetFormData(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/GetFormData/?id=${id}`;
        return api.get(options);
    },
    GetDraftFormData(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/GetDraftRequest/?id=${id}`;
        return api.get(options);
    },
    GetProductAuditFormData(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/audit/?id=${id}`;
        return api.get(options);
    },
    GetProductWorkflowStates(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/workflowstates/?id=${id}`;
        return api.get(options);
    },
    GetProductWorkflowHistoryStates(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/WorkflowHistoryStates/?id=${id}`;
        return api.get(options);
    },
    GetRequestWithTaskDetails(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/RequestWithActiveTaskDetails/?id=${id}`;
        return api.get(options);
    },
    RejectRequest(data, token, level) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/${level}`;
        return api.post(options);
    },
    ApproveRequestLevel1(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/ApproveLevel1`;
        return wiretransferapi.postWithFiles(options);
    },
    ApproveRequestLevel2(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/ApproveLevel2`;
        return api.post(options);
    },
    HoldRequestForReview(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/HoldForReview`;
        return api.post(options);
    },
    RequestForApproval(data, token, name) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/${name}`;
        return wiretransferapi.postWithFiles(options);
    },
    DownloadProductsUploadTemplate(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/DownloadProductsUploadTemplate`;
        return api.download(options);
    },
    GetProductsById(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/GetProductsById/?id=${id}`;
        return api.get(options);
    },
    GetRequests(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/List`;
        return api.list(options);
    },
    RenderUploadedFiles(token, id) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/RenderUploadedFiles/?id=${id}`;
        return api.get(options);
    },
    GetUsers(token, agencyName, name) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/${name}/?agency=${agencyName}`
        return api.get(options);
    },
    SaveAsDraft(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/SaveRequestAsDraft`;
        return wiretransferapi.postWithFiles(options);
    },
    GetBeneficiary(token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/Beneficiary`;
        return api.get(options);
    },
    DownloadECashRequest(token, id) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/DownloadECashRequest/${id}`;
        return wiretransferapi.download(options);
    },
    DownloadECashList(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/DownloadECashList`;
        return wiretransferapi.download(options);
    },
    DownloadList(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/DownloadList`;
        return api.download(options);
    },
    GetWireHistory(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/GetWireHistory/?id=${id}`;
        return api.get(options);
    },
    MarkAsReturn(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/MarkAsReturn`;
        return api.post(options);
    },
    UpdatePaymentDetails(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/UpdatePaymentDetails`;
        return api.post(options);
    },
    SaveAsTemplate(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/SaveRequestAsTemplate`;
        return wiretransferapi.postWithFiles(options);
    },
    ListAllTemplates(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/ListAllTemplates`;
        return api.post(options);
    },
    GetTemplate(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/GetTemplate/?id=${id}`;
        return api.get(options);
    }
}