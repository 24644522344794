import http from "./http";

export default {

  getBPMUsers(options,name,formType,token) {      
    let url=`/api/v1/MDM/GetAllBPMUsers/?env=${name}&formType=${formType}`;
    return http.get(url,{
      headers: {
      'Authorization': `Bearer ${token}`,
    }});
  },


  getUIDatas(options,name,token) {  
    let url=`/api/v1/MDM/GetCompleteEntityAgency/?dummyparam=${name}`;
    return http.get(url,{
      headers: {
      'Authorization': `Bearer ${token}`,
    }});
  },
  
  RemoveFileFromDirectory(options,param,token){         
    if (isNaN(param.requestid)) {      
      param.requestid=0;
    }
    
    let url=`/api/v1/InterCompany/RemoveFileFromDirectory/?requestId=${param.requestid}&filename=${param.name}`;
    return http.get(url,{
      headers: {
      'Authorization': `Bearer ${token}`,
    }});  
  },

  RemoveFileFromProfileDirectory(options,param,token){         
    if (isNaN(param.requestid)) {      
      param.requestid=0;
    }
    
    let url=`/api/v1/ProfileUnlock/RemoveFileFromDirectory/?requestId=${param.requestid}&filename=${param.name}`;
    return http.get(url,{
      headers: {
      'Authorization': `Bearer ${token}`,
    }});  
  },

  RemoveTempDirectory(options,guid,token){    
    let url=`/api/v1/MDM/RemovefromDirectory/?guid=${guid}`;
    return http.get(url,{
      headers: {
      'Authorization': `Bearer ${token}`,
    }});  
  },

  //DeleteICRequest
  UpdateICRequestGUID(token,requestId,guid){    
    window.console.log(requestId);
    let url=`/api/v1/Clients/UpdateDraftRequestId/?DraftRequestId=${requestId}&Guid=${guid}`;
    return http.get(url,{
      headers: {
      'Authorization': `Bearer ${token}`,
    }});  
  },



  RemoveClientTempDirectory(options,param,token){    
    //requestId, string filename
    let url=`/api/v1/MDM/RemoveFileFromDirectory/?requestId=${param.requestid}&filename=${param.name}&guid=${param.guidId}`;
    return http.get(url,{
      headers: {
      'Authorization': `Bearer ${token}`,
    }});  
  },

  getEntityRecords(options,name,token) {    
    let url=`/api/v1/MDM/GetEntity/?entityName=${name}`;
    return http.get(url,{
      headers: {
      'Authorization': `Bearer ${token}`,
    }});
  },

  getOriginalEntityRecords(options,name,token) {    
    let url=`/api/v1/MDM/GetEntityAgency/?entityName=${name}`;
    return http.get(url,{
      headers: {
      'Authorization': `Bearer ${token}`,
    }});
  },

  getAgencyRecords(options,name,token) {          
    let url=`/api/v1/MDM/GetEntityAgency?entityName=Agencies&activrecordid=${name}`;
    return http.get(url,{
      headers: {
      'Authorization': `Bearer ${token}`,
    }});
  },
  getAllAgencyRecords(options,token) {          
    let url=`/api/v1/MDM/GetAllEntityAgency?entityName=Agencies`;
    return http.get(url,{
      headers: {
      'Authorization': `Bearer ${token}`,
    }});
  },
  
  GetCheckHFMCodeExists(options,name,token) {     
    window.console.log(JSON.stringify(name));     
    let url=`/api/v1/MDM/GetHFMCodeExists?hfmcode=${name.HFMCode}&referenceCode=${name.referenceCode}`;
    return http.get(url,{
      headers: {
      'Authorization': `Bearer ${token}`,
    }});
  },

  getDropdownList(name) {
    return http.get(`data/${name}`);
  },
  getPaymentTerms(options) {
    return http.get(`${options.endpoint}`
    ,{
      headers: {
      'Authorization': `Bearer ${options.token}`,
    }}
  );
  },
  getAgencyList() {
    return http.get(`/api/v1/MDM/LoadAgency`);
  },

  getAgencyNetworkRegion(options,name,token) {          
    let url=`/api/v1/MDM/GetAgencyNetworkRegion?entityName=${name}`;
    return http.get(url,{
      headers: {
      'Authorization': `Bearer ${token}`,
    }});
  },
  
};
