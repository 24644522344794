import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);
import progress from "./modules/progress";
import client from "./modules/client";
import product from "./modules/product";
import user from "./modules/user";
import auth from "./modules/auth";
import intercompany from "./modules/intercompany";
import wiretransfer from "./modules/wiretransfer";
import mediavendor from "./modules/mediavendor";
import prodexpvendor from "./modules/prodexpvendor";
import vendormediaamend from "./modules/vendormediaamend";
import prodexpamend from "./modules/prodexpamend";
import profileUnlock from "./modules/profileUnlock";
import tabs from "./modules/tabs";
import vendorValidation from "./modules/vendorValidation";
import usersRoles from "./modules/usersRoles"

export default new Vuex.Store({
    modules: {
        progress,
        client,
        user,
        auth,
        product,
        intercompany,
        wiretransfer,
        mediavendor,
        prodexpvendor,
        vendormediaamend,
        prodexpamend,
        profileUnlock,
        tabs,
        vendorValidation,
        usersRoles,
    },
});