<template>
<app-layout  app_name="Financial Systems Request"  :show_app_grid="true" :show_profile="true"  :show_breadcrumb="true"     
     :first_name="UserName"  user_access_token="jhfjhfjh" user_email="test@omnicommediagroup.com" :last_name="LastName" :breadcrumb_trail="$root.breadcrumbTrail"   container_class="container-fluid" 
>
<MessageAlerts />

<router-view> 
Loading...
</router-view>
<loading-spinner message="Loading..." v-if="loadingModules > 0" />
</app-layout>
</template>


<style type="text/css">
@import url("./assets/Site.css");
@import url("./assets/WireTransfer.css");
@import url('https://fonts.googleapis.com/css?family=Open Sans');
#mainMenuContainer nav[data-v-412149be]{
  display: none !important;
}

 .vertical-nav
{
  background-color:  rgba(172, 200, 233, 1) !important;
}

nav ol.breadcrumb > .breadcrumb-item  a {
  text-transform:uppercase;
}
a.router-link-exact-active.router-link-active {
     background-color:transparent !important; 
}

.breadcrumb-item:last-child a {
    color: #1398ce !important;
}

.vertical-nav {  
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
}

.page-content {
  width: calc(100% - 17rem);
  margin-left: 17rem;
  transition: all 0.4s;
}

/* for toggle behavior */

#sidebar.active {
  margin-left: -17rem;
}

#content.active {
  width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  #sidebar {
    margin-left: -17rem;
  }
  #sidebar.active {
    margin-left: 10px;
    display: none !important;
  }
  #content {
    width: 100%;
    margin: 0;
  }
  #content.active {
    margin-left: 17rem;
    width: calc(100% - 17rem);
  }
}


body {
  background: #F6F9FA !important;
}

.separator {
  margin: 3rem 0;
}

.text-uppercase {
  letter-spacing: 0.1em;
}

.text-gray {
  color: black;
}
.container-true{
  margin-top:60px;
}
#body-container > .container {
  /* padding-left:3rem; */
  padding-top:3rem;
  /* padding-right:3rem; */
}
</style>

<script>
import { mapState } from 'vuex';
const MessageAlerts = () => import('./components/MessageAlerts.vue');
export default {
  components:{MessageAlerts},
  data(){
    return {
      menuItems:[
        {
          "name":"Home",
          "linkPath":"/ClientList",
           "iconUrl":"/icons/vendorRequest_modalIcon.svg",
        }
      ]
    }
  },computed:{
     ...mapState({
      loadingModules: state => state.progress.loadingModules,
      UserName:state=>state.user.UserName,
      // user_access_token: state => state.auth.accessToken,
    }),
    LastName(){
      return this.UserName? this.UserName.split(".")[1]:'';
    }
  }
}
</script>