import api from "../api/client";
const options = {
  endpoint: "api/v1/looker",
  baseEndpoint: "api/v1/looker",
  data: {},
};
export default {
  GetProducts(token, id) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetProducts/?clientId=${id}`;
    return api.get(options);
  },
  GetClients(token) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetClientList`;
    return api.get(options);
  },
  GetProductMedia(token, id) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetProductMedia/?productId=${id}`;
    return api.get(options);
  },
  GetClientMedia(token, id) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetClientMedia/?clientId=${id}`;
    return api.get(options);
  },
  GetMasterClient(token) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetMasterClientList`;
    return api.get(options);
  },
  GetAccountingOfficeCodes(token, data) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetAccountingOfficeCodes/?entityCode=${data.entityCode}`;
    return api.get(options);
  },
  GetMediaOfficeCodes(token, data) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetMediaOfficeCodes/?entityCode=${data.entityCode}&searchAccoffceKey=${encodeURIComponent(data.searchAccoffceKey)}`;
    return api.get(options);
  },
  GetBillingParty(token, data) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetBillingParty/?entityCode=${data.entityCode}`;
    return api.get(options);
  },
  GetCustomer(token) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetCustomerList`;
    return api.get(options);
  },
  GetReachUser(token, userEmail) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetReachUser/?userEmail=${userEmail}`;
    return api.get(options);
  },
  GetReachUserReportees(token, userEmail) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetReachUserReportees/?userEmail=${userEmail}`;
    return api.get(options);
  },
  JobLevelAuthentication(token) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/JobLevelAuthentication`;
    return api.get(options);
  },
  IsUserHasPermissions(token) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/IsUserHasPermissions`;
    return api.get(options);
  },
  GetAgencyListData(token) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetAgencyList`;
    return api.get(options);
  },
  GetSystemDropdown(token) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetSystemCodes`;
    return api.get(options);
  },
  GetMediaDropdown(token,data) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetMediaCode/?systemCode=${data.systemCode}`;
    return api.get(options);
  },
  GetCodeDropdown(token,data) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetVendorCode/?systemCode=${data.systemCode}&mediaName=${data.mediaName}`;
    return api.get(options);
  },
  GetD365VendorData(token) {
    options.token = token;
    options.endpoint = `${options.baseEndpoint}/GetD365VendorData`;
    return api.get(options);
  },
};
