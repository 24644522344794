//import axios from 'axios';

import HTTP from './http';
export default {
  post(options) {
    return HTTP.post(`/${options.endpoint}`, JSON.stringify(options.data), {
      headers: {
        'Authorization': `Bearer ${options.token}`,
        'Content-Type': 'application/json;charset=UTF-8;'
      }
    });
  },
  postWithFiles(options) {
    return HTTP.post(`/${options.endpoint}`, options.data, {
      headers: {
        'Authorization': `Bearer ${options.token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  delete(options) {
    return HTTP.delete(`/${options.endpoint}`, {
      headers: {
        'Authorization': `Bearer ${options.token}`,
        'Content-Type': 'application/json;charset=UTF-8;'
      },
      data:JSON.stringify(options.data)      
    });
  },
  put(options) {
    return HTTP.put(`/${options.endpoint}`, JSON.stringify(options.data), {
      headers: {
        'Authorization': `Bearer ${options.token}`,
        'Content-Type': 'application/json;charset=UTF-8;'
      }
    });
  },
  list(options) {
    return HTTP.post(`/${options.endpoint}/`, 
     JSON.stringify(options.data),{
      headers: {
        'Authorization': `Bearer ${options.token}`,
        'Content-Type': 'application/json;charset=UTF-8;'
      }
    });
  },
  get(options) {
    return HTTP.get(`/${options.endpoint}`, {
      headers: {
        'Authorization': `Bearer ${options.token}`,
      }
    });
  },
  download(options) {
    return HTTP.post(`/${options.endpoint}/`, 
    JSON.stringify(options.data),{
    responseType: 'blob',
     headers: {
       'Authorization': `Bearer ${options.token}`,
       'Content-Type': 'application/json;charset=UTF-8;'
     }
   });
  },
  putWithFiles(options) {
    return HTTP.put(`/${options.endpoint}/${options.id}`, options.data, {
      headers: {
        'Authorization': `Bearer ${options.token}`,
        'Content-Type': 'multipart/form-data'
      }
    });
  },
};
