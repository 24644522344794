import api from "../api/client";
const options = {
  endpoint: "api/v1/ProfileUnlock",
  baseEndpoint: "api/v1/ProfileUnlock",
  data: {}
};
export default { 
    DownloadList(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/DownloadList`;
        return api.download(options);
      },
  
}
