import axios from 'axios'
import Vue from 'vue'
import store from '@/store';
import auth from '@/services/auth';
Vue.use(axios);

const http = axios.create({
    baseURL: process.env.VUE_APP_API_ROOT,
    withCredentials: true,
    shouldKeepAlive: true,
    keepAlive: true,
    // keepAliveMsecs: 3000,
    // maxRedirects: 0,
    'Access-Control-Allow-Origin': '*',
});

let isAlreadyFetchingAccessToken = false;

// Array where we will keep track of all requests which need to be re-run after token refreshes
let subscribers = [];

// Array which tracks URLs of all requests which will be re-sent
// We track this so that requests only get re-run once, otherwise we could end up stuck in a loop
// if the token is valid, but the user doesn't have rights to perform a request
const refetchAttempts = [];

// Function which is actually run after token is refreshed
const onAccessTokenFetched = (idToken) => {
  subscribers = subscribers.filter(callback => callback(idToken));
};

// Function for adding another request to the subscriber list to re-run after token refresh
const addSubscriber = (callback) => {
  subscribers.push(callback);
};


http.interceptors.request.use((config) => {
    store.commit("progress/setProcessing", {
        root: true
    });
    config.headers.Authorization =`Bearer ${store.state.auth.idToken}`;
    return config
}, (error) => {
    store.commit("progress/removeProcessing", {
        root: true
    });
    return Promise.reject(error)
})

http.interceptors.response.use((response) => {
    store.commit("progress/removeProcessing", {
        root: true
    });
    return response
}, function (error) {
    store.commit("progress/removeProcessing", {
        root: true
    });
    const originalRequest = error.config;
    let status = null;
    if (error.response && error.response.status) {
      status = error.response.status;
    } else if (error.status) {
      status = error.status;
    }
    // If we receive a 401 Unauthorized response, we want to refresh our Access Token and try it again
    if ((!status || status === 401) && refetchAttempts.indexOf(originalRequest.url) === -1) {
      // Track that we're attempting to re-run request so we don't do it more than once
      refetchAttempts.push(originalRequest.url);
  
      // Refresh the Access Token, but only if we're not already doing it
      if (!isAlreadyFetchingAccessToken) {
        // Track that yes, we are refreshing the token
        isAlreadyFetchingAccessToken = true;
        // Dispatches an action in my Vuex store which refreshes the token
        store.dispatch('refreshToken').then((tokenResponse) => {
          if (tokenResponse) {
            // Mark that we're no longer refreshing the token
            isAlreadyFetchingAccessToken = false;
            // Re-run the requests now that the token is done
            onAccessTokenFetched(tokenResponse.id_token);
          } else {
            auth.authenticate('ONEWP-EVERYONE');
          }
        });
      }
  
      // Function which adds request to the list of requests to re-run
      const retryOriginalRequest = new Promise((resolve) => {
        addSubscriber((accessToken) => {
          originalRequest.headers.Authorization =`Bearer ${accessToken}`;
          resolve(http(originalRequest));
        });
      });
      return retryOriginalRequest;
    }
  
    return Promise.reject(error);
})
export default http;