import api from "../api/client";
const options = {
    endpoint: "api/v1/clients",
    baseEndpoint: "api/v1/clients",
    Icendpoint: "api/v1/InterCompany",
    IcbaseEndpoint: "api/v1/InterCompany",
    ProfilebaseEndpoint: "api/v1/ProfileUnlock",
    data: {},
};
export default {
    AddClient(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/`;
        // options.endpoint = `api/client/Add`;
        return api.postWithFiles(options);
    },

    AddProfileWithfile(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}/`;
        return api.postWithFiles(options);
    },

    UpdateProfileWithfile(id, data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}`;
        options.id = id;
        return api.putwithFiles(options);
    },

    AddIC(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.IcbaseEndpoint}/`;
        return api.post(options);
    },

    SaveClientDraft(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/SaveClientasDraft`;
        return api.postWithFiles(options);
    },
    SaveInterCompanyDraft(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.IcbaseEndpoint}/SaveInterCompanyasDraft`;
        return api.post(options);
    },

    ProfileUnlockRequest(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}/`;
        return api.post(options);
    },

    GetADEmpDetails(data, token) {
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}/GetADUserDetails?searchKey=${data}`;
        return api.get(options);
    },

    GetProfileUnlockRequestWithTaskDetails(obj, token) {
        window.console.log(JSON.stringify(obj));
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}/GetProfileUnlockRequestWithTaskDetails?id=${obj.id}&&isedit=${obj.isedit}`;
        return api.get(options);
    },

    GetAllProfileUnlockRequests(data, token) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.ProfilebaseEndpoint}/List`;
        return api.list(options);
    },

    GetAllInterCompanyAgencies(data, token) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.IcbaseEndpoint}/List`;
        return api.list(options);
    },

    ApproveProfileUnlockRequestLevel1(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}/ApproveLevel1`;
        return api.post(options);
    },

    ApproveProfileUnlockRequestLevel2(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}/ApproveLevel2`;
        return api.post(options);
    },

    ApproveProfileUnlockRequestComplete(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}/Complete`;
        return api.post(options);
    },

    RejectProfileUnlockRequestLevel1(data, token, level) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}/${level}`;
        return api.post(options);
    },

    RejectProfileUnlockRequestLevel2(data, token, level) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}/${level}`;
        return api.post(options);
    },

    DownloadProfileFile(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.ProfilebaseEndpoint}/DownloadFile`;
        return api.download(options);
    },

    GetProfileAuditFormData(id, token) {
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}/Audit/?id=${id}`;
        return api.get(options);
    },

    GetProfileWorkflowHistoryStates(id, token) {
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}/WorkflowHistoryStates/?id=${id}`;
        return api.get(options);
    },

    UpdateProfileUnlock(id, data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}`;
        options.id = id;
        return api.put(options);
    },

    DeleteProfileUnlock(token, id) {
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}/${id}`;
        return api.delete(options);
    },

    UpdateIC(data, token) {
        options.data = data;
        options.token = token;
        options.id = data.ID;
        options.endpoint = `${options.IcbaseEndpoint}`;
        window.console.log(JSON.stringify(options.endpoint));
        return api.putWithoutId(options);
    },

    // DeleteIC(token,data) {
    //   window.console.clear();
    //   options.token =token;
    //   options.data=data;
    //   options.endpoint=`${options.IcbaseEndpoint}`;
    //   return api.delete(options);
    // },

    GetIC(token, id) {
        options.token = token;
        options.endpoint = `${options.IcbaseEndpoint}/${id}`;
        return api.get(options);
    },

    GetCompleteICRequest(token, id) {
        options.token = token;
        options.endpoint = `${options.IcbaseEndpoint}/CompleteICRequest/?requestid=${id}`;
        return api.get(options);
    },

    GetICRequestWithTaskDetails(id, token) {
        options.token = token;
        options.endpoint = `${options.IcbaseEndpoint}/ICRequestWithActiveTaskDetails/?id=${id}`;
        return api.get(options);
    },

    ApproveICRequestLevel1(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.IcbaseEndpoint}/ApproveLevel1`;
        return api.post(options);
    },

    ApproveICRequestLevel2(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.IcbaseEndpoint}/ApproveLevel2`;
        return api.post(options);
    },

    RejectICRequest(data, token, level) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.IcbaseEndpoint}/${level}`;
        return api.post(options);
    },

    CompleteICRequest(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.IcbaseEndpoint}/Complete`;
        return api.post(options);
    },

    GetICAuditFormData(id, token) {
        options.token = token;
        options.endpoint = `${options.IcbaseEndpoint}/Audit/?id=${id}`;
        return api.get(options);
    },

    UpdateClient(id, data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = options.baseEndpoint;
        // options.endpoint = `api/client/Update`;
        options.id = id;
        return api.putwithFiles(options);
    },
    GetRequests(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/List`;
        return api.list(options);
    },
    GetClientFormData(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/${id}`;
        return api.get(options);
    },
    GetClientAuditFormData(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/audit/?id=${id}`;
        return api.get(options);
    },
    GetClientWorkflowStates(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/workflowstates/?id=${id}`;
        return api.get(options);
    },
    GetClientWorkflowHistoryStates(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/WorkflowHistoryStates/?id=${id}`;
        return api.get(options);
    },

    GetICWorkflowHistoryStates(id, token) {
        options.token = token;
        options.endpoint = `${options.IcbaseEndpoint}/WorkflowHistoryStates/?id=${id}`;
        return api.get(options);
    },
    GetClientRequestWithTaskDetails(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/ClientRequestWithActiveTaskDetails/?id=${id}`;
        return api.get(options);
    },
    RejectRequest(data, token, level) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/${level}`;
        return api.post(options);
    },

    RejectRequestatLast(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/RejectatFinalLevel`;
        return api.post(options);
    },

    ApproveRequest(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/Approve`;
        return api.post(options);
    },
    ApproveRequestLevel1(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/ApproveLevel1`;
        return api.post(options);
    },
    ApproveRequestLevel2(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/ApproveLevel2`;
        return api.post(options);
    },

    CompleteIC(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/Complete`;
        return api.post(options);
    },

    CompleteRequest(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/CompleteRequest`;
        return api.post(options);
    },
    Complete(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/Complete`;
        return api.post(options);
    },
    TriggerTask(data, token, action) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/${action}`;
        return api.post(options);
    },
    DownloadList(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/DownloadList`;
        return api.download(options);
    },
    RenderUploadedFiles(token, id) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/RenderUploadedFiles/?id=${id}`;
        return api.get(options);
    },
    RenderDraftUploadedFiles(token, id) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/RenderDraftUploadedFiles/?id=${id}`;
        return api.get(options);
    },
    DeleteUploadedFile(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/DeleteUploadedFile`;
        return api.delete(options);
    },
    DownloadFile(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/DownloadFile`;
        return api.download(options);
    },

    DownloadICFile(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.IcbaseEndpoint}/DownloadFile`;
        return api.download(options);
    },
    DeleteIC(token, id) {
        options.token = token;
        options.endpoint = `${options.IcbaseEndpoint}/${id}`;
        return api.delete(options);
    },
    Delete(token, id) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/${id}`;
        return api.delete(options);
    },
    DeleteDraft(token, id) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/DeleteDraft/?id=${id}`;
        return api.delete(options);
    },

    DownloadProductsUploadTemplate(token, data) {
        options.token = token;
        options.data = data;
        options.endpoint = `${options.baseEndpoint}/DownloadProductsUploadTemplate`;
        return api.download(options);
    },

    GetClientPropertyByCode(token, code, propertyName) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/PropertyValueByCode/?clientCode=${code}&propertyName=${propertyName}`;
        return api.get(options);
    },

    GetClientDraftData(id, token) {
        options.token = token;
        options.endpoint = `${options.baseEndpoint}/GetDraftRequest/?id=${id}`;
        return api.get(options);
    },
    BulkRelock(data, token) {
        options.data = data;
        options.token = token;
        options.endpoint = `${options.ProfilebaseEndpoint}/BulkRelock`;
        return api.post(options);
    },
};