import wireTransferService from "../../services/wiretransfer";
const getDefaultState = () => {
    return {
        WireTransfer: {
            IsNewVendor: null,
            PaymentType: null,
            Company: null,
            VendorSystem: null,
            VendorCode: null,
            MediaType: null,
            TransferType: "Domestic",
            DebitCompany: null,
            Beneficiary: null,
            BeneficiaryStreetAddress: null,
            BeneficiaryCountry: null,
            BeneficiaryCity: null,
            BeneficiaryZipCode: null,
            BeneficiaryReference: null,
            CreditAccountName: null,
            CreditAccountNumber: null,
            Currency: null,
            BankName: null,
            BankCountry: null,
            BankBICType: null,
            BankBIC: null,
            InvoiceCurrency: null,
            TotalInvoiceAmount: null,
            RequestedTransferDate: new Date(),
            PayingBankCharges: "Remitter",
            ReceivingBankCharges: "Beneficiary",
            RemittanceAdvice: "Yes",
            Paythrough: "No",
            PaythroughBankName: null,
            PaythroughBankCountry: null,
            PaythroughBICType: null,
            PaythroughBIC: null,
            AuthorizedBy: null,
            Watchers: [],
            Files: [],
            Notes: null,
            DebitAccountNumber: null

        }
    }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
    WireTransferRequest: state => {
        return state.WireTransfer;
    }

};



// actions
const actions = {
    GetFormData(st, id) {
        return wireTransferService.GetFormData(id, st.rootState.auth.idToken);
    },
    GetDraftFormData(st, id) {
        return wireTransferService.GetDraftFormData(id, st.rootState.auth.idToken);
    },
    GetRequestWithTaskDetails(st, id) {
        return wireTransferService.GetRequestWithTaskDetails(id, st.rootState.auth.idToken);
    },
    AddRequest(st, data) {
        return wireTransferService.AddRequest(data, st.rootState.auth.idToken);
    },
    AddRequestWithFiles(st, data) {
        return wireTransferService.AddRequestWithFiles(data, st.rootState.auth.idToken);
    },
    ApproveRequestLevel1(st, data) {
        return wireTransferService.ApproveRequestLevel1(data, st.rootState.auth.idToken);
    },
    RequestCFOApproval(st, data) {
        return wireTransferService.RequestForApproval(data, st.rootState.auth.idToken, 'RequestCFOApproval');
    },
    ApproveRequestLevel2(st, data) {
        return wireTransferService.ApproveRequestLevel2(data, st.rootState.auth.idToken);
    },
    RejectRequestLevel1(st, data) {
        return wireTransferService.RejectRequest(data, st.rootState.auth.idToken, "RejectLevel1");
    },
    RejectRequestLevel2(st, data) {
        return wireTransferService.RejectRequest(data, st.rootState.auth.idToken, "RejectLevel2");
    },
    CancelRequest(st, data) {
        return wireTransferService.RejectRequest(data, st.rootState.auth.idToken, "Cancel");
    },
    HoldRequestForReview(st, data) {
        return wireTransferService.HoldRequestForReview(data, st.rootState.auth.idToken);
    },
    GetRequests(st, options) {
        return wireTransferService.GetRequests(st.rootState.auth.idToken, options);
    },
    RenderUploadedFiles(st, id) {
        return wireTransferService.RenderUploadedFiles(st.rootState.auth.idToken, id);
    },
    UpdateRequestWithFiles(st, { id, data }) {
        return wireTransferService.UpdateRequestWithFiles(id, data, st.rootState.auth.idToken);
    },
    GetUsers(st, { agencyName, name }) {
        return wireTransferService.GetUsers(st.rootState.auth.idToken, agencyName, name);
    },
    SaveAsDraft(st, data) {
        return wireTransferService.SaveAsDraft(data, st.rootState.auth.idToken);
    },
    GetBeneficiary(st) {
        return wireTransferService.GetBeneficiary(st.rootState.auth.idToken);
    },
    DownloadECashRequest(st, id) {
        return wireTransferService.DownloadECashRequest(st.rootState.auth.idToken, id);
    },
    DownloadECashList(st, data) {
        return wireTransferService.DownloadECashList(st.rootState.auth.idToken, data);
    },
    DownloadList(st, data) {
        return wireTransferService.DownloadList(st.rootState.auth.idToken, data);
    },
    GetWireHistory(st, id) {
        return wireTransferService.GetWireHistory(id, st.rootState.auth.idToken);
    },
    MarkAsReturn(st, data) {
        return wireTransferService.MarkAsReturn(data, st.rootState.auth.idToken);
    },
    UpdatePaymentDetails(st, data) {
        return wireTransferService.UpdatePaymentDetails(data, st.rootState.auth.idToken);
    },
    SaveAsTemplate(st, data) {
        return wireTransferService.SaveAsTemplate(data, st.rootState.auth.idToken);
    },
    ListAllTemplates(st, data) {
        return wireTransferService.ListAllTemplates(data, st.rootState.auth.idToken);
    },
    GetTemplate(st, id) {
        return wireTransferService.GetTemplate(id, st.rootState.auth.idToken);
    }
};

// mutations
const mutations = {

};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};