/* eslint-disable no-param-reassign */
// initial state
const state = {
    errorMessage: null,
    successMessage: null,
    loadingModules: 0,
  };
  
  // getters
  const getters = {};
  
  
  // actions
  const actions = {
    setErrorMessage({ commit }, message) {
      commit('setError', message);
    },
  
    setSuccessMessage({ commit }, message) {
      commit('setSuccess', message);
    },
  
    setProcessing({ commit }, moduleName) {
      commit('setProcessing', moduleName);
    },
  };
  
  // mutations
  const mutations = {
    setError(st, message) {
      // eslint-disable-next-line no-param-reassign
      st.errorMessage = message;
    },
  
    setSuccess(st, message) {
      // eslint-disable-next-line no-param-reassign
      st.successMessage = message;
    },
  
    setProcessing(st) {
        st.loadingModules=st.loadingModules+1;
    },
  
    removeProcessing(st) {
      if(st.loadingModules>0){
      st.loadingModules=st.loadingModules-1;
      }
    },
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  