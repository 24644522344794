
import intercompanyService from "../../services/intercompany";

const getDefaultState = () => {
  return {

  }    
}

// initial state
const state = getDefaultState()

// getters
const getters = {  

};

// actions
const actions = {
  getIntercompanyDraftData(st, id) {    
   return  intercompanyService.GetIntercompanyDraftData(id, st.rootState.auth.idToken);      
  }, 

};

// mutations
const mutations = {
  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};