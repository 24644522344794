import productService from "../../services/product";
import lookerService from "../../services/looker";

const getDefaultState = () => {
  return {};
};

// initial state
const state = getDefaultState();

// getters
const getters = {};

// const options = {
//   endpoint: "users"
// };

// actions
const actions = {
  getProductFormData(st, id) {
    return productService.GetProductFormData(id, st.rootState.auth.idToken);
  },
  GetProductAuditFormData(st, id) {
    return productService.GetProductAuditFormData(
      id,
      st.rootState.auth.idToken
    );
  },
  GetProductWorkflowStates(st, id) {
    return productService.GetProductWorkflowStates(
      id,
      st.rootState.auth.idToken
    );
  },
  GetProductWorkflowHistoryStates(st, id) {
    return productService.GetProductWorkflowHistoryStates(
      id,
      st.rootState.auth.idToken
    );
  },
  GetProductRequestWithTaskDetails(st, id) {
    return productService.GetProductRequestWithTaskDetails(
      id,
      st.rootState.auth.idToken
    );
  },
  AddProduct(st, data) {
    return productService.AddProduct(data, st.rootState.auth.idToken);
  },
  AddRequestWithFiles(st, data) {
    return productService.AddRequestWithFiles(data, st.rootState.auth.idToken);
  },
  ApproveRequestLevel1(st, data) {
    return productService.ApproveRequestLevel1(data, st.rootState.auth.idToken);
  },
  ApproveRequestLevel2(st, data) {
    return productService.ApproveRequestLevel2(data, st.rootState.auth.idToken);
  },
  RejectRequestLevel1(st, data) {
    return productService.RejectRequest(
      data,
      st.rootState.auth.idToken,
      "RejectLevel1"
    );
  },
  RejectRequestLevel2(st, data) {
    return productService.RejectRequest(
      data,
      st.rootState.auth.idToken,
      "RejectFinalLevel"
    );
  },
  GetProductList(st, id) {
    return productService.GetProductsById(id, st.rootState.auth.idToken);
  },
  UpdateProduct(st, { id, data }) {
    return productService.UpdateProduct(id, data, st.rootState.auth.idToken);
  },
  Delete(st, id) {
    return productService.Delete(id, st.rootState.auth.idToken);
  },
  GetClients(st) {
    return lookerService.GetClients(st.rootState.auth.idToken);
  },
  GetReachUser(st, userEmail) {
    return lookerService.GetReachUser(st.rootState.auth.idToken, userEmail);
  },
  GetReachUserReportees(st, userEmail) {
    return lookerService.GetReachUserReportees(st.rootState.auth.idToken, userEmail);
  },
  GetAgencyListData(st) {
    return lookerService.GetAgencyListData(st.rootState.auth.idToken);
  },
  JobLevelAuthentication(st) {
    return lookerService.JobLevelAuthentication(st.rootState.auth.idToken);
  },
  IsUserHasPermissions(st) {
    return lookerService.IsUserHasPermissions(st.rootState.auth.idToken);
  },
  GetProducts(st, id) {
    return lookerService.GetProducts(st.rootState.auth.idToken, id);
  },
  GetClientMedia(st, id) {
    return lookerService.GetClientMedia(st.rootState.auth.idToken, id);
  },
  GetProductMedia(st, id) {
    return lookerService.GetProductMedia(st.rootState.auth.idToken, id);
  },
  DownloadProductsUploadTemplate(st, data) {
    return productService.DownloadProductsUploadTemplate(
      st.rootState.auth.idToken,
      data
    );
  },
  UpdateRequestWithFiles(st, { id, data }) {
    return productService.UpdateRequestWithFiles(
      id,
      data,
      st.rootState.auth.idToken
    );
  },
  GetSystemDropdown(st) {
    return lookerService.GetSystemDropdown(st.rootState.auth.idToken);
  },
  GetMediaDropdown(st) {
    return lookerService.GetMediaDropdown(st.rootState.auth.idToken);
  },
  GetCodeDropdown(st) {
    return lookerService.GetCodeDropdown(st.rootState.auth.idToken);
  },
};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
