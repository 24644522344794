import vendorValidationService from "../../services/vendorValidation";
const getDefaultState = () => {
  return {
    VendorValidation: {
      ID: 0,
      AttemptCounter: 0,
      DateTime: null,
      MissingVendorDocs: [],
      AttachmentFiles:  [],
      ReachingMethod: [],
      VendorContactName: null,
      VendorTitle: null,
      VendorPhoneNumber: null,
      VendorEmail: null,
      ConfirmPaymentInfo: null,
      VendorACHInfo: null,
      ValidationComments: null,
    },
  };
};

// initial state
const state = getDefaultState();

// getters
const getters = {
  VendorValidation: (state) => {
    return state.VendorValidation;
  },
};

// actions
const actions = {
  updateMapInAction({commit},event) {
    commit("updateMap",event)
  },
  GetRequestWithTaskDetails(st, id) {
    vendorValidationService
      .GetRequestWithTaskDetails(id, st.rootState.auth.idToken)
      .then((res) => {
        st.commit("loadVendorValidationData", res.data);
        return res.data;
      })
      .catch((err) => {
        return err;
      });
  },
  SaveVendorValidation(st, data) {
    return vendorValidationService.SaveVendorValidation( data, st.rootState.auth.idToken);
  },
  GetAttemptHistory(st, id) {
    return vendorValidationService.GetAttemptHistory(
      id,
      st.rootState.auth.idToken
    );
  },
  UpdateVendorValidation(st, { id, data }) {
    return vendorValidationService.UpdateVendorValidation(
      id,
      data,
      st.rootState.auth.idToken
    );
  },
  GetVendorValidationDetails(st, { id, vendorType}) {
    return vendorValidationService.GetVendorValidationDetails(
      id,
      vendorType,
      st.rootState.auth.idToken
    );
  },
};
// mutations
const mutations = {
  clearVendorValidationData(state) {
    Object.assign(state.VendorValidation, getDefaultState().VendorValidation);
  },
  loadVendorValidationData(state, data) {
    state.VendorValidation = data;
    state.VendorValidation.ID = data.ID;
    state.VendorValidation.AttemptCounter = data.AttemptCounter;
    state.VendorValidation.DateTime = data.DateTime;
    state.VendorValidation.ReachingMethod = data.ReachingMethod.split(',');
    state.VendorValidation.MissingVendorDocs = data.MissingVendorDocs.split(',');
    state.VendorValidation.VendorContactName = data.VendorContactName;
    state.VendorValidation.VendorPhoneNumber = data.VendorPhoneNumber;
    state.VendorValidation.VendorEmail = data.VendorEmail;
    state.VendorValidation.ConfirmPaymentInfo =
      data.ConfirmPaymentInfo == true ? "true" : "false";
      state.VendorValidation.VendorACHInfo =
        data.VendorACHInfo == true ? "true" : "false";
    state.VendorValidation.ValidationComments = data.ValidationComments;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
