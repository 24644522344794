import api from "../api/client";
import client from "../api/wiretransfer";

const validationOptions={
  endpoint: "api/v1/Vendor",
  baseEndpoint: "api/v1/Vendor",
  data: {},
};
export default {
      SaveVendorValidation(data,token){
        validationOptions.data=data;
        validationOptions.token=token;
        validationOptions.endpoint= `${validationOptions.baseEndpoint}`
        return client.postWithFiles(validationOptions);
      },
      GetAttemptHistory(id, token) {
        validationOptions.token = token;
        validationOptions.endpoint = `${validationOptions.baseEndpoint}/GetAttemptHistory/?id=${id}`;
        return api.get(validationOptions);
      },
      UpdateVendorValidation(id, data, token) {
        validationOptions.data = data;
        validationOptions.token = token;
        validationOptions.id = id;
        validationOptions.endpoint = `${validationOptions.baseEndpoint}`;
        return client.putWithFiles(validationOptions);
      },
      GetVendorValidationDetails(id, vendorType, token) {
        validationOptions.token = token;
        validationOptions.endpoint = `${validationOptions.baseEndpoint}/GetVendorValidationDetails/?id=${id}&vendorType=${vendorType}`;
        return api.get(validationOptions);
      },
};