/* eslint-disable no-param-reassign */
// initial state
const state = {
    IsAuthenticated:false,
    UserName:null,
    Token:null

  };
  
  // getters
  const getters = {
    IsAuthenticated:state=>{
      return state.IsAuthenticated;
    },
    UserDetails:state=>{
      return state.UserName;
    },
    UserToken:state=>{
      return state.Token;
    },
  };
  
  
  // actions
  const actions = {
    setUser({ commit }, userName) {
      commit('setUser', userName);
    },
  };
  
  // mutations
  const mutations = {
    setUser(st, userName) {
      // eslint-disable-next-line no-param-reassign
      st.IsAuthenticated = true;
      st.UserName=userName.userName;
      st.Token=userName.token;
    },     
   
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };
  