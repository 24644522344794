import VueRouter from "vue-router";
import Vue from "vue";
import store from "@/store";
//import service from './services/user'
const moment = require("moment");
import auth from "@/services/auth";
Vue.use(VueRouter);
let routes = [{
        path: "/",
        component: () =>
            import ("./components/ClientList"),
        meta: { title: "Client List", auth: true },
    },
    {
        path: "/callback",
        name: "Callback",
        meta: {
            auth: false,
            allowAnonymous: true,
        },
        component: () =>
            import ("./views/Auth/Callback"),
    },
    {
        path: "/Request/Client",
        name: "Client",
        component: () =>
            import ("./views/ClientHome"),
        props: (route) => Object.assign({}, route.query, route.params),
        children: [{
                path: "ClientInfo/:id?",
                component: () =>
                    import ("./components/Client/ClientInfo"),
                name: "Client Info",
                meta: { tabIndex: 0, breadcrumbTrail: [{ name: "Create New Client" }] },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "EulerGrade/:id?",
                name: "Euler Grade",
                component: () =>
                    import ("./components/Client/EulerGrade"),
                meta: { tabIndex: 1, breadcrumbTrail: [{ name: "Create New Client" }] },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "BillingInfo/:id?",
                name: "Billing",
                component: () =>
                    import ("./components/Client/BillingInfo"),
                meta: { tabIndex: 2, breadcrumbTrail: [{ name: "Create New Client" }] },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "CommissionInfo/:id?",
                name: "Commission Info",
                component: () =>
                    import ("./components/Client/CommissionInfo"),
                meta: { tabIndex: 3, breadcrumbTrail: [{ name: "Create New Client" }] },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "ProductInfo/:id?",
                name: "Product Info",
                component: () =>
                    import ("./components/Client/ProductInfo"),
                meta: { tabIndex: 4, breadcrumbTrail: [{ name: "Create New Client" }] },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "Review/:id?",
                name: "Review",
                component: () =>
                    import ("./components/Client/Review"),
                meta: { tabIndex: 5, breadcrumbTrail: [{ name: "Create New Client" }] },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "/Client",
                redirect: "/Client/ClientInfo",
                props: (route) => Object.assign({}, route.query, route.params),
            },
        ],
    },
    {
        path: "/Request/Vendor/ProdExp",
        name: "Vendor",
        component: () =>
            import ("./views/VendorHome"),
        props: (route) => Object.assign({}, route.query, route.params),
        children: [{
                path: "EmployeeInfo/:id?",
                component: () =>
                    import ("./components/Vendor/ProdEXP/EmployeeInfo"),
                name: "Production Expense Vendor Employee Info",
                meta: {
                    tabIndex: 0,
                    breadcrumbTrail: [{ name: "Create New Production Expense" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "Attestation/:id?",
                name: "Production Expense Vendor Attestation",
                component: () =>
                    import ("./components/Vendor/ProdEXP/Attestation"),
                meta: {
                    tabIndex: 1,
                    breadcrumbTrail: [{ name: "Create New Production Expense" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "SetupInfo/:id?",
                name: "Production Expense Vendor Setup Info",
                component: () =>
                    import ("./components/Vendor/ProdEXP/SetupInfo"),
                meta: {
                    tabIndex: 2,
                    breadcrumbTrail: [{ name: "Create New Production Expense" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "BankingInfo/:id?",
                name: "Production Expense Vendor Banking Info",
                component: () =>
                    import ("./components/Vendor/ProdEXP/BankingInfo"),
                meta: {
                    tabIndex: 3,
                    breadcrumbTrail: [{ name: "Create New Production Expense" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "Services/:id?",
                name: "Production Expense Vendor Services",
                component: () =>
                    import ("./components/Vendor/ProdEXP/Services"),
                meta: {
                    tabIndex: 4,
                    breadcrumbTrail: [{ name: "Create New Production Expense" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "Relationship/:id?",
                name: "Production Expense Vendor Relationship",
                component: () =>
                    import ("./components/Vendor/ProdEXP/Relationship"),
                meta: {
                    tabIndex: 5,
                    breadcrumbTrail: [{ name: "Create New Production Expense" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "Review/:id?",
                name: "Production Expense Vendor Review",
                component: () =>
                    import ("./components/Vendor/ProdEXP/Review"),
                meta: {
                    tabIndex: 6,
                    breadcrumbTrail: [{ name: "Create New Production Expense" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
        ],
    },
    {
        path: "/Request/Vendor/ProdExp/ApprovalLevel1/:id",
        component: () =>
            import ("./components/Vendor/ProdEXP/ApprovalLevel1"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpVendorApproveLevel1",
    },
    {
        path: "/Request/Vendor/ProdExp/ApprovalLevel2/:id",
        component: () =>
            import ("./components/Vendor/ProdEXP/ApprovalLevel2"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpVendorApproveLevel2",
    },
    {
        path: "/Request/Vendor/ProdExp/VendorValidation/:id",
        component: () =>
            import ("./components/Vendor/ProdEXP/VendorValidation"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpVendorVendorValidation",
    },
    {
        path: "/Request/Vendor/ProdExp/ApprovalLevel3/:id",
        component: () =>
            import ("./components/Vendor/ProdEXP/ApprovalLevel3"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpVendorApproveLevel3",
    },
    {
        path: "/Request/Vendor/ProdExp/AccountingApproval/:id",
        component: () =>
            import ("./components/Vendor/ProdEXP/AccountingApproval"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpVendorAccountingApproval",
    },
    {
        path: "/Request/Vendor/ProdExp/CFOApproval/:id",
        component: () =>
            import ("./components/Vendor/ProdEXP/CFOApproval"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpVendorCFOApproval",
    },
    {
        path: "/Request/Vendor/ProdExp/Complete/:id",
        component: () =>
            import ("./components/Vendor/ProdEXP/Complete"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpVendorComplete",
    },
    {
        path: "/Request/Product/:id?",
        name: "Product",
        component: () =>
            import ("./components/Product/NewProduct"),
        meta: { breadcrumbTrail: [{ name: "Product" }] },
        props: (route) => Object.assign({}, route.query, route.params),
    },
    {
        path: "/ProductApproveLevel1/:id",
        component: () =>
            import ("./components/Product/ApproveLevel1"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProductApproveLevel1",
    },
    {
        path: "/ProductApproveLevel2/:id",
        component: () =>
            import ("./components/Product/ApproveLevel2"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProductApproveLevel2",
    },
    {
        path: "/Request/InterCompanyAgency/:requestid?",
        name: "InterCompany Agency",
        component: () =>
            import ("./components/InterCompany"),
        props: (route) => Object.assign({}, route.query, route.params),
        meta: { breadcrumbTrail: [{ name: "InterCompany" }] },
    },
    {
        path: "/Request/ProfileUnlock/:requestid?/:isClone?",
        name: "Profile Unlock",
        component: () =>
            import ("./components/Profile/ProfileUnlock"),
        props: (route) => Object.assign({}, route.query, route.params),
        meta: { breadcrumbTrail: [{ name: "UnlockProfile" }] },
    },
    {
        path: "/Profile/ProfileApproveLevel1/:requestnumber",
        component: () =>
            import ("./components/Profile/ApprovalLevel1"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProfileApproveLevel1",
    },
    {
        path: "/Profile/ProfileApproveLevel2/:requestnumber",
        component: () =>
            import ("./components/Profile/ApprovalLevel2"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProfileApproveLevel2",
    },
    {
        path: "/Profile/Complete/:requestnumber",
        component: () =>
            import ("./components/Profile/Complete"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProfileComplete",
    },

    {
        path: "/Request/Vendor",
        name: "Vendor Request",
        component: () =>
            import ("./components/VendorRequest"),
        meta: { breadcrumbTrail: [{ name: "Vendor Request" }] },
    },
    {
        path: "/:tab",
        name: "Client List",
        component: () =>
            import ("./components/ClientList"),
        meta: { breadcrumbTrail: [{ name: "Client List" }] },
    },
    {
        path: "/ClientRequestComplete/:id",
        component: () =>
            import ("./components/Client/CompleteRequest"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ClientRequestComplete",
    },
    {
        path: "/ClientComplete/:id",
        component: () =>
            import ("./components/Client/Complete"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ClientComplete",
    },
    {
        path: "/Request/Client",
        name: "Client",
        component: () =>
            import ("./views/ClientHome"),
        props: (route) => Object.assign({}, route.query, route.params),
        children: [{
                path: "ClientInfo/:id?",
                component: () =>
                    import ("./components/Client/ClientInfo"),
                name: "Client Info",
                meta: { tabIndex: 0, breadcrumbTrail: [{ name: "Create New Client" }] },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "EulerGrade/:id?",
                name: "Euler Grade",
                component: () =>
                    import ("./components/Client/EulerGrade"),
                meta: { tabIndex: 1, breadcrumbTrail: [{ name: "Create New Client" }] },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "BillingInfo/:id?",
                name: "Billing",
                component: () =>
                    import ("./components/Client/BillingInfo"),
                meta: { tabIndex: 2, breadcrumbTrail: [{ name: "Create New Client" }] },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "CommissionInfo/:id?",
                name: "Commission Info",
                component: () =>
                    import ("./components/Client/CommissionInfo"),
                meta: { tabIndex: 3, breadcrumbTrail: [{ name: "Create New Client" }] },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "ProductInfo/:id?",
                name: "Product Info",
                component: () =>
                    import ("./components/Client/ProductInfo"),
                meta: { tabIndex: 4, breadcrumbTrail: [{ name: "Create New Client" }] },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "Review/:id?",
                name: "Review",
                component: () =>
                    import ("./components/Client/Review"),
                meta: { tabIndex: 5, breadcrumbTrail: [{ name: "Create New Client" }] },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "/Client",
                redirect: "/Client/ClientInfo",
                props: (route) => Object.assign({}, route.query, route.params),
            },
        ],
    },

    {
        path: "/Request/Product/:id?",
        name: "Product",
        component: () =>
            import ("./components/Product/NewProduct"),
        meta: { breadcrumbTrail: [{ name: "Product" }] },
        props: (route) => Object.assign({}, route.query, route.params),
    },
    {
        path: "/ProductApproveLevel1/:id",
        component: () =>
            import ("./components/Product/ApproveLevel1"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProductApproveLevel1",
    },
    {
        path: "/ProductApproveLevel2/:id",
        component: () =>
            import ("./components/Product/ApproveLevel2"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProductApproveLevel2",
    },
    {
        path: "/Request/InterCompanyAgency/:requestid?",
        name: "InterCompany Agency",
        component: () =>
            import ("./components/InterCompany"),
        props: (route) => Object.assign({}, route.query, route.params),
        meta: { breadcrumbTrail: [{ name: "InterCompany" }] },
    },
    {
        path: "/Request/ProfileUnlock/:requestid?",
        name: "Profile Unlock",
        component: () =>
            import ("./components/Profile/ProfileUnlock"),
        props: (route) => Object.assign({}, route.query, route.params),
        meta: { breadcrumbTrail: [{ name: "UnlockProfile" }] },
    },
    {
        path: "/Profile/ProfileApproveLevel1/:requestnumber",
        component: () =>
            import ("./components/Profile/ApprovalLevel1"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProfileApproveLevel1",
    },
    {
        path: "/Profile/ProfileApproveLevel2/:requestnumber",
        component: () =>
            import ("./components/Profile/ApprovalLevel2"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProfileApproveLevel2",
    },
    {
        path: "/Profile/Complete/:requestnumber",
        component: () =>
            import ("./components/Profile/Complete"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProfileComplete",
    },

    {
        path: "/Request/Vendor",
        name: "Vendor Request",
        component: () =>
            import ("./components/VendorRequest"),
        meta: { breadcrumbTrail: [{ name: "Vendor Request" }] },
    },
    {
        path: "/:tab",
        name: "Client List",
        component: () =>
            import ("./components/ClientList"),
        meta: { breadcrumbTrail: [{ name: "Client List" }] },
    },
    {
        path: "/ClientRequestComplete/:id",
        component: () =>
            import ("./components/Client/CompleteRequest"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ClientRequestComplete",
    },
    {
        path: "/ClientComplete/:id",
        component: () =>
            import ("./components/Client/Complete"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ClientComplete",
    },

    {
        path: "/IC/ICApproveLevel1/:requestnumber",
        component: () =>
            import ("./components/ICOverview"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ICApproveLevel1",
    },

    {
        path: "/IC/ICApproveLevel2/:requestnumber",
        component: () =>
            import ("./components/ICApproveLevel2"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ICApproveLevel2",
    },

    {
        path: "/IC/Complete/:requestnumber",
        component: () =>
            import ("./components/ICComplete"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ICComplete",
    },

    {
        path: "/ClientApproveLevel1/:id",
        component: () =>
            import ("./components/Client/Approve"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ClientApproveLevel1",
    },
    {
        path: "/ClientApproveLevel2/:id",
        component: () =>
            import ("./components/Client/ApproveLevel2"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ClientApproveLevel2",
    },
    {
        path: "/ClientApproveICRequest/:id",
        component: () =>
            import ("./components/Client/ApproveICRequest"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ClientApproveICRequest",
    },
    {
        path: "/ClientApproveICRequest/:id",
        component: () =>
            import ("./components/Client/ApproveICRequest"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ClientApproveLevelICRequest",
    },
    {
        path: "/Request/WireTransfer/:id?",
        name: "Wire Transfer",
        component: () =>
            import ("./views/WireTransferRequest"),
        props: (route) => Object.assign({}, route.query, route.params),
        meta: { breadcrumbTrail: [{ name: "Wire Transfer" }] },
    },
    {
        path: "/WireTransferApproveLevel1/:id",
        component: () =>
            import ("./components/WireTransfer/ApproveLevel1"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "WireTransferApproveLevel1",
    },
    {
        path: "/WireTransferApproveLevel2/:id",
        component: () =>
            import ("./components/WireTransfer/ApproveLevel2"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "WireTransferApproveLevel2",
    },
    {
        path: "/WireTransferReview/:id",
        component: () =>
            import ("./components/WireTransfer/Review"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "WireTransferReview",
    },
    {
        path: "/Header",
        component: () =>
            import ("./components/Layout")
    },
    {
        path: "/unauthorized",
        name: "unauthorized",
        component: () =>
            import ("./components/Unauthorized"),
        props: true,
    },
    {
        path: "/unauthorizedReachData",
        name: "unauthorizedReachData",
        component: () =>
            import ("./components/UnauthorizedReachData"),
        props: true,
    },
    {
        path: "/unauthorizedProdExpCreate",
        name: "unauthorizedProdExpCreate",
        component: () =>
            import ("./components/UnauthorizedProdExpCreate"),
        props: true,
    },
    {
        path: "/error",
        name: "error",
        component: () =>
            import ("./components/Error"),
        props: true,
    },
    {
        path: "/Request/MediaVendor",
        name: "MediaVendor",
        component: () =>
            import ("./views/MediaVendor"),
        props: (route) => Object.assign({}, route.query, route.params),
        children: [{
                path: "LedgerMedia/:id?",
                name: "Media Vendor Ledger Media",
                component: () =>
                    import ("./components/Vendor/Media/LedgerMediaInfo"),
                meta: {
                    tabIndex: 0,
                    breadcrumbTrail: [{ name: "Create New Media Vendor" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "ExternalForm/:id?",
                name: "Media Vendor External Attachment",
                component: () =>
                    import ("./components/Vendor/Media/Attestation"),
                meta: {
                    tabIndex: 1,
                    breadcrumbTrail: [{ name: "Create New Media Vendor" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "CreditCardInfo/:id?",
                component: () =>
                    import ("./components/Vendor/Media/CreditCardInfo"),
                name: "Media Vendor Credit Info",
                meta: {
                    tabIndex: 2,
                    breadcrumbTrail: [{ name: "Create New Media Vendor" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "SetupInfo/:id?",
                name: "Media Vendor Setup Info",
                component: () =>
                    import ("./components/Vendor/Media/SetupInfo"),
                meta: {
                    tabIndex: 3,
                    breadcrumbTrail: [{ name: "Create New Media Vendor" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "EmployeeRelationship/:id?",
                name: "Media Vendor Relationship Info",
                component: () =>
                    import ("./components/Vendor/Media/EmployeeRelationship"),
                meta: {
                    tabIndex: 4,
                    breadcrumbTrail: [{ name: "Create New Media Vendor" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "EmployeeInfo/:id?",
                name: "Media Vendor Employee Info",
                component: () =>
                    import ("./components/Vendor/Media/EmployeeInfo"),
                meta: {
                    tabIndex: 5,
                    breadcrumbTrail: [{ name: "Create New Media Vendor" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "Review/:id?",
                name: "Media Vendor Review",
                component: () =>
                    import ("./components/Vendor/Media/Review"),
                meta: {
                    tabIndex: 6,
                    breadcrumbTrail: [{ name: "Create New Media Vendor" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
        ],
    },
    {
        path: "/MediaVendorApproveLevel1/:id",
        component: () =>
            import ("./components/Vendor/Media/ApproveLevel1"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "MediaVendorApproveLevel1",
    },
    {
        path: "/MediaVendorValidation/:id",
        component: () =>
            import ("./components/Vendor/Media/VendorValidation"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "MediaVendorValidation",
    },
    {
        path: "/MediaVendorApproveLevel2/:id",
        component: () =>
            import ("./components/Vendor/Media/ApproveLevel2"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "MediaVendorApproveLevel2",
    },
    {
        path: "/MediaVendorAccountingApproval/:id",
        component: () =>
            import ("./components/Vendor/Media/AccountingApproval"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "MediaVendorAccountingApproval",
    },
    {
        path: "/MediaVendorCFOApproval/:id",
        component: () =>
            import ("./components/Vendor/Media/CFOApproval"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "MediaVendorCFOApproval",
    },
    {
        path: "/MediaVendorComplete/:id",
        component: () =>
            import ("./components/Vendor/Media/Complete"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "MediaVendorComplete",
    },
    {
        path: "/Request/Vendor/Media/Amend",
        name: "MediaVendorAmend",
        component: () =>
            import ("./views/VendorMediaAmend"),
        props: (route) => Object.assign({}, route.query, route.params),
        children: [{
                path: "EmployeeInfo/:id?",
                component: () =>
                    import ("./components/Vendor/MediaAmend/EmployeeInfo"),
                name: "Media Vendor Amend Employee Info",
                meta: {
                    tabIndex: 0,
                    breadcrumbTrail: [{ name: "Media Vendor Amend" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "SetupInfo/:id?",
                name: "Media Vendor Amend Setup Info",
                component: () =>
                    import ("./components/Vendor/MediaAmend/SetupInfo"),
                meta: {
                    tabIndex: 1,
                    breadcrumbTrail: [{ name: "Media Vendor Amend" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "CreditCardInfo/:id?",
                name: "Media Vendor Amend Credit Card Info",
                component: () =>
                    import ("./components/Vendor/MediaAmend/CreditCardInfo"),
                meta: {
                    tabIndex: 2,
                    breadcrumbTrail: [{ name: "Media Vendor Amend" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            // {
            //   path: "BankingInfo/:id?",
            //   name: "Media Vendor Amend Banking Info",
            //   component: () => import("./components/Vendor/MediaAmend/BankingInfo"),
            //   meta: { tabIndex: 3 , breadcrumbTrail: [{ name: "Media Vendor Amend" },]},
            //   props: route => Object.assign({}, route.query, route.params),
            // },
            {
                path: "EmployeeRelationship/:id?",
                name: "Media Vendor Amend Employee Relationship",
                component: () =>
                    import ("./components/Vendor/MediaAmend/EmployeeRelationship"),
                meta: {
                    tabIndex: 3,
                    breadcrumbTrail: [{ name: "Media Vendor Amend" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "Review/:id?",
                name: "Media Vendor Amend Review",
                component: () =>
                    import ("./components/Vendor/MediaAmend/Review"),
                meta: {
                    tabIndex: 4,
                    breadcrumbTrail: [{ name: "Media Vendor Amend" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
        ],
    },
    {
        path: "/AmendMediaVendorApproveLevel1/:id",
        component: () =>
            import ("./components/Vendor/MediaAmend/ApproveLevel1"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "AmendMediaVendorApproveLevel1",
    },
    {
        path: "/AmendMediaVendorValidation/:id",
        component: () =>
            import ("./components/Vendor/MediaAmend/VendorValidation"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "AmendMediaVendorValidation",
    },
    {
        path: "/AmendMediaVendorApproveLevel2/:id",
        component: () =>
            import ("./components/Vendor/MediaAmend/ApproveLevel2"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "AmendMediaVendorApproveLevel2",
    },
    {
        path: "/AmendMediaVendorAccountingApproval/:id",
        component: () =>
            import ("./components/Vendor/MediaAmend/AccountingApproval"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "AmendMediaVendorAccountingApproval",
    },
    {
        path: "/AmendMediaVendorCFOApproval/:id",
        component: () =>
            import ("./components/Vendor/MediaAmend/CFOApproval"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "AmendMediaVendorCFOApproval",
    },
    {
        path: "/AmendMediaVendorComplete/:id",
        component: () =>
            import ("./components/Vendor/MediaAmend/Complete"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "AmendMediaVendorComplete",
    },
    {
        path: "/Request/Vendor/ProdEXPAmends/",
        name: "ProdExpenseAmend",
        component: () =>
            import ("./views/ProdExpenseAmend"),
        props: (route) => Object.assign({}, route.query, route.params),
        children: [{
                path: "EmployeeInfo/:id?",
                component: () =>
                    import ("./components/Vendor/ProdEXPAmends/EmployeeInfo"),
                name: "Production Expense Amend Employee Info",
                meta: {
                    tabIndex: 0,
                    breadcrumbTrail: [{ name: "Production Expense Amend" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "SetupInfo/:id?",
                name: "Production Expense Amend Setup Info",
                component: () =>
                    import ("./components/Vendor/ProdEXPAmends/SetupInfo"),
                meta: {
                    tabIndex: 1,
                    breadcrumbTrail: [{ name: "Production Expense Amend" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "BankingInfo/:id?",
                name: "Production Expense Amend Banking Info",
                component: () =>
                    import ("./components/Vendor/ProdEXPAmends/BankingInfo"),
                meta: {
                    tabIndex: 2,
                    breadcrumbTrail: [{ name: "Production Expense Amend" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "EmployeeRelationship/:id?",
                name: "Production Expense Amend Employee Relationship",
                component: () =>
                    import ("./components/Vendor/ProdEXPAmends/EmployeeRelationship"),
                meta: {
                    tabIndex: 3,
                    breadcrumbTrail: [{ name: "Production Expense Amend" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
            {
                path: "Review/:id?",
                name: "Production Expense Amend Review",
                component: () =>
                    import ("./components/Vendor/ProdEXPAmends/Review"),
                meta: {
                    tabIndex: 4,
                    breadcrumbTrail: [{ name: "Production Expense Amend" }],
                },
                props: (route) => Object.assign({}, route.query, route.params),
            },
        ],
    },
    {
        path: "/Request/Vendor/ProdExpAmend/ApprovalLevel1/:id",
        component: () =>
            import ("./components/Vendor/ProdEXPAmends/ApprovalLevel1"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpAmendVendorApproveLevel1",
    },
    {
        path: "/Request/Vendor/ProdExpAmend/ApprovalLevel2/:id",
        component: () =>
            import ("./components/Vendor/ProdEXPAmends/ApprovalLevel2"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpAmendVendorApproveLevel2",
    },
    {
        path: "/Request/Vendor/ProdExpAmend/VendorValidation/:id",
        component: () =>
            import ("./components/Vendor/ProdEXPAmends/VendorValidation"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpAmendVendorVendorValidation",
    },
    {
        path: "/Request/Vendor/ProdExpAmend/ApprovalLevel3/:id",
        component: () =>
            import ("./components/Vendor/ProdEXPAmends/ApprovalLevel3"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpAmendVendorApproveLevel3",
    },
    {
        path: "/Request/Vendor/ProdExpAmend/AccountingApproval/:id",
        component: () =>
            import ("./components/Vendor/ProdEXPAmends/AccountingApproval"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpAmendVendorAccountingApproval",
    },
    {
        path: "/Request/Vendor/ProdExpAmend/CFOApproval/:id",
        component: () =>
            import ("./components/Vendor/ProdEXPAmends/CFOApproval"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpAmendVendorCFOApproval",
    },
    {
        path: "/Request/Vendor/ProdExpAmend/Complete/:id",
        component: () =>
            import ("./components/Vendor/ProdEXPAmends/Complete"),
        props: (route) => Object.assign({}, route.query, route.params),
        name: "ProdExpAmendVendorComplete",
    },
];
const router = new VueRouter({
    mode: "history",
    routes: routes,
});

router.beforeEach((to, from, next) => {
    if (moment(store.getters.expires).isAfter()) {
        //  store.dispatch('getUser', false);
        // next();
        store.dispatch("getUser", false).then(() => {
            next();
        });
    } else if (store.state.auth.refreshToken) {
        store.dispatch("refreshToken").then((token) => {
            if (!token) {
                store.commit("setRequestedRoute", to.path);
                auth.authenticate("ONEWP-EVERYONE");
            }
            // store.dispatch('getUser', false).then(()=>{
            next();
            // });
        });
    } else if (window.location.pathname !== "/callback") {
        store.commit("setRequestedRoute", window.location.pathname);
        auth.authenticate("ONEWP-EVERYONE");
    } else {
        next();
    }
    const tabName = to.params.tab;
    if (tabName) {
        store.commit("setCurrentTab", tabName);
    }
});

export default router;