import Vue from "vue";
import App from "./App.vue";

import { BootstrapVue } from "bootstrap-vue";
import "@omg-technology-shared-resources/omni-styles";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@omg-technology-shared-resources/omni-styles/dist/omni/omni.css";
// import 'omg-technology-shared-resources/dist/omni/omni.css';
import router from "./router";
import store from "./store/index";
import Vuelidate from "vuelidate";
import VueExcelXlsx from "vue-excel-xlsx";
import VCalendar from "v-calendar";
import VueSignalR from "@latelier/vue-signalr";
import ToggleSwitch from "vuejs-toggle-switch";

Vue.component("ic-audit", () => import("./components/ICAudit"));
Vue.component("IC-overview", () => import("./components/Client/Overview"));
Vue.component("client-overview", () => import("./components/Client/Overview"));
Vue.component("client-audit", () => import("./components/Client/AuditTrail"));
Vue.component("profile-audit", () => import("./components/Profile/Audit"));
Vue.component("product-overview", () =>
  import("./components/Product/Overview")
);
Vue.component("product-audit", () => import("./components/Product/AuditTrail"));
Vue.component("custom-tooltip", () => import("./components/Tooltip"));
Vue.component("wiretransfer-overview", () =>
  import("./components/WireTransfer/Overview")
);
Vue.component("media-vendor-overview", () =>
  import("./components/Vendor/Media/Overview")
);
Vue.component("media-vendor-audit", () =>
  import("./components/Vendor/Media/AuditTrail")
);
Vue.component("prodexp-vendor-overview", () =>
  import("./components/Vendor/ProdEXP/OverView")
);
Vue.component("prodexp-vendor-audit", () =>
  import("./components/Vendor/ProdEXP/AuditTrail")
);
Vue.component("media-amend-overview", () =>
  import("./components/Vendor/MediaAmend/Overview")
);
Vue.component("media-amend-audit", () =>
  import("./components/Vendor/MediaAmend/AuditTrail")
);
Vue.component("prodexp-amend-audit", () =>
  import("./components/Vendor/ProdEXPAmends/AuditTrail")
);
Vue.component("prodexp-amend-overview", () =>
  import("./components/Vendor/ProdEXPAmends/Overview")
);
Vue.component("vendor-validation-details", () =>
  import("./components/Vendor/VendorValidation/VendorValidationDetails")
);

// Vuelidate.prototype.$lazy=true;
// Vuelidate.prototype.$autoDirty=true;
Vue.use(VCalendar);
Vue.use(Vuelidate);
Vue.use(BootstrapVue);
Vue.use(VueExcelXlsx);
Vue.use(ToggleSwitch);
Vue.use(
  VueSignalR,
  `${process.env.VUE_APP_API_ROOT}wiretransfertransactionhub`
);
Vue.config.productionTip = false;
Vue.directive("numericOnly", {
  bind(el) {
    el.addEventListener("keyup", () => {
      let regex = /^[0-9]*$/;
      if (!regex.test(el.value)) {
        el.value = el.value.slice(0, -1);
      }
    });
  },
});
String.prototype.insert = function(index, string) {
  if (index > 0) {
    return this.substring(0, index) + string + this.substr(index);
  }

  return string + this;
};
Vue.directive("USTaxIDFormat", {
  bind(el) {
    el.addEventListener("keyup", () => {
      let regex = /([0-9]{2})[-]?([0-9]*)/;
      if (!regex.test(el.value)) {
        el.value = el.value.slice(0, -1);
      } else if (
        el.value &&
        el.value.toString().length > 2 &&
        el.value.toString().indexOf("-") != 2
      ) {
        el.value = el.value.insert(2, "-");
      }
    });
  },
});
Vue.directive("numericWithDecimal", {
  bind(el) {
    el.addEventListener("keyup", () => {
      let regex = /^[0-9]*[.]?[0-9]*$/;
      if (!regex.test(el.value)) {
        el.value = el.value.slice(0, -1);
      }
    });
  },
});
new Vue({
  el: "#app",
  router,
  store,
  render: (g) => g(App),
  //components:{App},
  //template:"</App>",
  data: () => ({
    breadcrumbTrail: [],
  }),
  methods: {
    setUpBreadcrumb() {
      const vm = this;
      vm.$router.afterEach((to) => {
        vm.breadcrumbTrail = to.meta.breadcrumbTrail;
      });
      vm.breadcrumbTrail = vm.$route.meta.breadcrumbTrail;
    },
  },
  created() {
    this.setUpBreadcrumb();
    // store.commit("client/ResetValues");
  },
});
