/* eslint-disable no-param-reassign */
const promisify = (xhr, failNon2xx = true) => {
  const oldSend = xhr.send;
  xhr.send = () => {
    // eslint-disable-next-line no-undef
    const xhrArguments = arguments;
    return new Promise(((resolve, reject) => {
      // Note that when we call reject, we pass an object
      // with the request as a property. This makes it easy for
      // catch blocks to distinguish errors arising here
      // from errors arising elsewhere. Suggestions on a
      // cleaner way to allow that are welcome.
      xhr.onload = () => {
        if (failNon2xx && (xhr.status < 200 || xhr.status >= 300)) {
          reject(new Error('Error loading data'));
        } else {
          resolve(xhr);
        }
      };
      xhr.onerror = () => {
        reject(new Error('Error loading data'));
      };
      oldSend.apply(xhr, xhrArguments);
    }));
  };
};

export default promisify;
