import ProfileUnlockServices from "../../services/profileUnlock";

const getDefaultState = () => {
  return {

  }    
}
const state = getDefaultState()

const getters = {  

};

const actions = {
    DownloadList(st, data) {
        return ProfileUnlockServices.DownloadList(st.rootState.auth.idToken, data);
      },

};

const mutations = {
  
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};