import api from "../api/client";
const options = {
  endpoint: "api/v1/InterCompany",
  baseEndpoint: "api/v1/InterCompany",
  data: {}
};
export default { 
  GetIntercompanyDraftData(id, token) {
    options.token = token;
    options.endpoint=`${options.baseEndpoint}/GetDraftRequest/?id=${id}`;
    return api.get(options);
  },
  
}